<template>
  <div style="width: 100%; display: contents">
    <!--  -->
   
    <template v-if="isLoading">
      <ColonBroomLoading v-if="isColonBroomSingleQuiz.quizId==getSelectedPage.quiz_id"></ColonBroomLoading>
      <LoaderMain v-else />
    </template>
   
    <div
      v-else-if="!isLeadComp"
      :class="
        RoutePage == 'QuizFinal'
          ? 'w-100 '
          : checkIsElthBaldanClient
          ? 'w-100'
          : 'col-12 d-flex justify-content-center p-0 mb-5'
      "
    >
      <script2 v-if="getScriptValue">
        {{ getScriptValue }}
      </script2>
      <div
        :class="
          RoutePage == 'QuizFinal'
            ? 'row m-0 p-0 w-100 big-div-quiz'
            : checkIsElthBaldanClient
            ? 'row m-0 p-0 w-100 big-div-quiz'
            : 'row mt-0 mt-sm-5 w-85 big-div'
        "
        style="position: relative; z-index: 100; overflow: hidden auto"
        :style="getPageDesign()"
      >
        <!-- Background Div Start -->
        <div
          class="bg lazyload"
          :data-bg="MainImgSrc(ImgBlockImageSrc)"
          v-if="
            products.length > 0
              ? pageDesign.imageBlock.src !== ''
              : getImageAlign == 'no' &&
                pageDesign.imageBlock.noResultSrc !== ''
          "
          :style="
            getbackgroundImage(
              IsImageAdded,
              pageDesign.imageBlock,
              getImageAlign,
              isDesktopView
            ) + getBgDivStyles()
          "
        ></div>
        <!-- Background Div End -->

        <div
          class="px-0"
          v-show="showLeftImage && pageDesign.pageType !== 'resultPage'"
          :class="[
            {
              'col-12 col-lg-12 col-xs-12': !isDesktopView,
              'image-division col-6': isDesktopView,
            },
          ]"
          :style="
            calculateImageWidth(
              pageDesign.imageBlock,
              isDesktopView,
              IsImageVariableWidthActive
            )
          "
        >
          <div :class="isDesktopView ? 'image-division' : ''">
            <div :style="height100(isDesktopView)">
              <LazyImage
                :style="
                  getUniversalImageStyle(
                    isDesktopView,
                    pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
                  ) +
                  getImageOpacity(pageDesign.imageBlock.style) +
                  getImageRotation(pageDesign.imageBlock.style)
                "
                :class="[
                  `pic-${getSelectedPage.order}`,
                  ImgBlockImageSrc == '/images/default_start_page.png'
                    ? 'default-bg'
                    : '',
                ]"
                :src="MainImgSrc(ImgBlockImageSrc)"
                alt
              />
            </div>
          </div>
        </div>

        <!-- Main Section -->
        <div
          :class="[
            !IsImageAdded ? 'col-12' : imageAddedLayout,

            getPreviewSettings.quizId == baldanClientId ? 'pr-md-0 pr-4' : '',
          ]"
          :style="
            getScrollCSS(
              pageDesign.pageStyles,
              isDesktopView,
              pageDesign.imageBlock.style,
              pageDesign.pageType
            ) +
            calculateContentWidth(
              pageDesign.imageBlock,
              isDesktopView,
              IsImageVariableWidthActive,
              pageDesign.pageStyles,
              pageDesign.pageType
            ) +
            getMainDivPadding
          "
        >
          <div class="">
            <div>
              <div v-if="products.length > 0">
                <div
                  v-for="(pro, ind) in pageDesign.blocksArray"
                  :key="'preview' + ind"
                  :class="[
                    'item-' + ind,
                    pro.type === 'tooltip' ? 'tooltip-zIndex' : '',
                  ]"
                >
                  <div
                    id="preview"
                    :style="getHeightWidth(pro.style)"
                    class="content"
                    style="position: relative"
                  >
                    <div
                      v-if="
                        pro.type == 'text' &&
                        !allowedAuthUser.includes(getPreviewSettings.quizId)
                      "
                      :style="
                        getColorCss(pro.style) +
                        getPaddingCss(pro.style) +
                        getMarginCss(pro.style, isDesktopView) +
                        getColorWithOpacity(pro.style) +
                        getTextRotate(pro.style) +
                        getLineHeight(pro.style) +
                        getTextAlign(pro.style) +
                        getFontStyle(pro.style, isDesktopView)
                      "
                      v-html="getTextValue(pro.content)"
                    ></div>
                    <div
                      v-if="pro.type == 'html'"
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getColorWithOpacity(pro.style) +
                        getTextRotate(pro.style) +
                        getTextAlign(pro.style)
                      "
                    >
                      <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
                    </div>
                    <div v-if="pro.type == 'imageComp'">
                      <InlineImageComp
                        :pro="pro"
                        :isDesktopView="isDesktopView"
                      />
                    </div>

                    <!-- Products -->
                    <div v-if="pro.type == 'product'">
                      <div v-if="!isSpecialClient">
                        <!-- Detail Only View  start-->
                        <div class="row" v-if="getViewType == 'detailOnly'">
                          <div class="w-100" v-if="pro.showListView">
                            <div
                              class="col-12"
                              v-for="(product, index) in getProducts(pro)"
                              :key="index"
                            >
                              <div
                                class="row m-0 p-0 mb-3"
                                :style="
                                  getCardBackground(pro.style) +
                                  getCardBorderCSS(pro.style) +
                                  detailProductContainerStyle(
                                    isDesktopView,
                                    showImage(product.image)
                                  )
                                "
                              >
                                <div
                                  class="p-0 m-0"
                                  v-if="showImage(product.image)"
                                  :class="
                                    getProductCarouselImageClass(isDesktopView)
                                  "
                                  :style="
                                    getProductCarouselImageStyle(isDesktopView)
                                  "
                                >
                                  <div
                                    class="w-100 h-100"
                                    style="position: relative"
                                  >
                                    <!-- Product Rank Start -->
                                    <div
                                      style="
                                        position: absolute;
                                        top: 0%;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        z-index: 100;
                                      "
                                      class="d-flex justify-content-center"
                                      v-if="
                                        pro.showProductRank &&
                                        index <= getProducts(pro).length
                                      "
                                    >
                                      <p
                                        style="text-align: center"
                                        :style="getProductRankCss(pro.style)"
                                        v-if="
                                          pro.productRankOption[index] &&
                                          pro.productRankOption[index].text !=
                                            ''
                                        "
                                      >
                                        <span>{{
                                          pro.productRankOption[index].text
                                        }}</span>
                                      </p>
                                    </div>
                                    <!-- Product Rank End -->

                                    <div
                                      v-if="product.image"
                                      style="height: 100%"
                                    >
                                      <div
                                        v-if="
                                          checkFileType(product.image) ==
                                          'video'
                                        "
                                        style="height: 100%"
                                      >
                                        <video
                                          ref="videoRef"
                                          :src="`${product.image}`"
                                          :style="
                                            getDetailProductImageFit(
                                              pro.style
                                            ) +
                                            getDetailProductImageRatio(
                                              pro.style
                                            ) +
                                            getImageborderRadius(pro.style)
                                          "
                                          style="width: 100%; height: 100%"
                                          controls
                                        ></video>
                                      </div>
                                      <div v-else style="height: 100%">
                                        <LazyImage
                                          v-if="
                                            product.image.startsWith('upload')
                                          "
                                          :src="'/' + product.image"
                                          alt=""
                                          :style="
                                            getDetailProductImageFit(
                                              pro.style
                                            ) +
                                            getDetailProductImageRatio(
                                              pro.style
                                            ) +
                                            getImageborderRadius(pro.style)
                                          "
                                        />
                                        <LazyImage
                                          v-else
                                          :src="product.image"
                                          alt=""
                                          :style="
                                            getDetailProductImageFit(
                                              pro.style
                                            ) +
                                            getDetailProductImageRatio(
                                              pro.style
                                            ) +
                                            getImageborderRadius(pro.style)
                                          "
                                        />
                                      </div>
                                    </div>
                                    <LazyImage
                                      v-else
                                      :src="'/images/image_default_placeholder.png'"
                                      alt=""
                                      :style="
                                        getDetailProductImageFit(pro.style) +
                                        getDetailProductImageRatio(pro.style) +
                                        getImageborderRadius(pro.style)
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  class="h-100 w-100 d-flex flex-column"
                                  :class="
                                    showImage(product.image)
                                      ? getProductCarouselImageClass(
                                          isDesktopView
                                        )
                                      : 'col-12'
                                  "
                                  :style="
                                    getPagePadding(pro.style, isDesktopView)
                                  "
                                >
                                  <!-- Title & price -->
                                  <div
                                    v-if="showImage(product.image)"
                                    class="px-3"
                                  >
                                    <p :style="getTitleStyles(pro.style)">
                                      {{
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          "title"
                                        )
                                      }}
                                    </p>
                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        product.rating && isJudgeMeInstalled
                                      "
                                      class="Review px-3"
                                      v-html="product.rating"
                                      :style="getPriceColor(pro.style)"
                                    ></div>
                                    <!-- Rating Div end -->
                                  </div>
                                  <div
                                    v-else
                                    class=""
                                    style=""
                                  >
                                    <p :style="getTitleStyles(pro.style)">
                                      {{
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          "title"
                                        )
                                      }}
                                    </p>
                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        product.rating && isJudgeMeInstalled
                                      "
                                      class="Review px-3"
                                      v-html="product.rating"
                                      :style="getPriceColor(pro.style)"
                                    ></div>
                                    <!-- Rating Div end -->
                                  </div>
                                  <!-- Product Price , features  -->
                                  <div
                                    class="d-flex flex-column justify-content-between"
                                    :class="
                                      showImage(
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          'image'
                                        )
                                      )
                                        ? 'flex-grow-1'
                                        : null
                                    "
                                  >
                                    <!-- Description Start -->
                                    <div
                                      class="flex-grow-1"
                                      v-if="!pro.hideDescription"
                                    >
                                      <div
                                        :style="
                                          limitDeatilProductDescription(
                                            pro.limitDescription,
                                            product.readMore,
                                            isDesktopView,
                                            product.detail_link
                                          )
                                        "
                                        v-if="
                                          showImage(
                                            getProductInfoDetail(
                                              pro.useProductVariant,
                                              product,
                                              'image'
                                            )
                                          )
                                        "
                                      >
                                        <div
                                          v-if="
                                            pro.useBulletDescription &&
                                            product.bullet_description &&
                                            product.bullet_description.length
                                          "
                                        >
                                          <div
                                            v-for="(
                                              bulletPoint, index2
                                            ) in product.bullet_description"
                                            :key="index2"
                                          >
                                            <div
                                              class="d-flex align-items-start mt-1"
                                              :style="
                                                getBulletStyles(pro.style)
                                              "
                                            >
                                              <div class="mr-3">
                                                <i
                                                  class="fa"
                                                  :class="
                                                    pro.selectedBulletIcon
                                                  "
                                                ></i>
                                              </div>
                                              <span style="text-align: start">{{
                                                bulletPoint
                                              }}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="w-100 detailOnlyDescriptionContainer"
                                          v-else
                                          :style="`font-family:${pro.style.fontFamily};`"
                                          v-html="product.description"
                                        ></div>
                                      </div>
                                      <div
                                        :style="
                                          limitDeatilProductDescription(
                                            pro.limitDescription,
                                            product.readMore,
                                            isDesktopView
                                          )
                                        "
                                        v-else
                                        class="d-flex justify-content-center"
                                      >
                                        <div
                                          v-if="
                                            pro.useBulletDescription &&
                                            product.bullet_description &&
                                            product.bullet_description.length
                                          "
                                        >
                                          <div
                                            v-for="(
                                              bulletPoint, index2
                                            ) in product.bullet_description"
                                            :key="index2"
                                          >
                                            <div
                                              class="d-flex align-items-start mt-1"
                                              :style="
                                                getBulletStyles(pro.style)
                                              "
                                            >
                                              <div class="mr-3">
                                                <i
                                                  class="fa"
                                                  :class="
                                                    pro.selectedBulletIcon
                                                  "
                                                ></i>
                                              </div>
                                              <span style="text-align: start">{{
                                                bulletPoint
                                              }}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="w-100 detailOnlyDescriptionContainer"
                                          v-else
                                          :style="`font-family:${pro.style.fontFamily};`"
                                          v-html="product.description"
                                        ></div>
                                      </div>

                                      <div
                                        class=""
                                        v-if="
                                          pro.limitDescription &&
                                          product.description &&
                                          product.description
                                            .split(' ')
                                            .join('').length > 150 &&
                                          !pro.hideDescription &&
                                          false
                                        "
                                      >
                                        <button
                                          class="btn mt-2"
                                          :style="getPriceColor(pro.style)"
                                          @click="
                                            product.readMore = !product.readMore
                                          "
                                        >
                                          <span v-if="product.readMore">{{
                                            translateText("Less info")
                                          }}</span>
                                          <span v-else
                                            >{{ translateText("More info") }}
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <!-- Description End -->
                                    <!-- Button Start -->
                                    <div class="mt-auto">
                                      <div class="">
                                        <div
                                          class="d-flex align-items-center px-3"
                                          :style="
                                            'justify-content:' +
                                            pro.style.textAlign +
                                            ';'
                                          "
                                          v-if="
                                            showImage(
                                              getProductInfoDetail(
                                                pro.useProductVariant,
                                                product,
                                                'image'
                                              )
                                            )
                                          "
                                        >
                                          <p
                                            v-if="product.price && !isHidePrice"
                                            :style="getPriceColor(pro.style)"
                                            class="mr-2"
                                          >
                                            <span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'left'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >{{
                                              getProductInfoDetail(
                                                pro.useProductVariant,
                                                product,
                                                "price"
                                              )
                                            }}<span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'right'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >
                                          </p>
                                          <p
                                            v-if="
                                              product.compare_at_price &&
                                              product.price !==
                                                product.compare_at_price &&
                                              !isHidePrice
                                            "
                                            :style="getPriceColor(pro.style)"
                                          >
                                            <s>
                                              <span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'left'
                                                "
                                                :class="
                                                  currencySpace ? 'mr-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >{{ product.compare_at_price
                                              }}<span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'right'
                                                "
                                                :class="
                                                  currencySpace ? 'mr-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >
                                            </s>
                                          </p>
                                        </div>
                                        <div
                                          class="d-flex align-items-center justify-content-center px-3"
                                          v-else
                                        >
                                          <p
                                            v-if="product.price && !isHidePrice"
                                            :style="getPriceColor(pro.style)"
                                            class="mr-2"
                                          >
                                            <span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'left'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >{{
                                              getProductInfoDetail(
                                                pro.useProductVariant,
                                                product,
                                                "price"
                                              )
                                            }}<span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'right'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >
                                          </p>
                                          <p
                                            v-if="
                                              product.compare_at_price &&
                                              product.price !==
                                                product.compare_at_price &&
                                              !isHidePrice
                                            "
                                            :style="getPriceColor(pro.style)"
                                          >
                                            <s>
                                              <span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'left'
                                                "
                                                :class="
                                                  currencySpace ? 'mr-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >{{ product.compare_at_price
                                              }}<span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'right'
                                                "
                                                :class="
                                                  currencySpace ? 'ml-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >
                                            </s>
                                          </p>
                                        </div>
                                      </div>
                                      <div v-if="product.detail_link">
                                        <div
                                          v-if="
                                            showImage(
                                              getProductInfoDetail(
                                                pro.useProductVariant,
                                                product,
                                                'image'
                                              )
                                            )
                                          "
                                        >
                                          <div
                                            class="pb-2"
                                            v-if="
                                              pro.useProductVariant &&
                                              product &&
                                              product.variants
                                            "
                                          >
                                            <VaraintSelectComp
                                              :pro="pro"
                                              :product="product"
                                            />
                                          </div>

                                          <button
                                            v-if="pro.useAddToCart"
                                            class="ButtonHover"
                                            :style="
                                              getProductButtonCss(pro.style)
                                            "
                                            :disabled="disableAddtoCartBtn"
                                            @click="AddToCart(product, 0)"
                                          >
                                            <span v-if="!product.loading">{{
                                              pro.useAddToCartText ||
                                              "Add to Cart"
                                            }}</span>
                                            <div
                                              v-else
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                            >
                                              <span class="sr-only"
                                                >Loading...</span
                                              >
                                            </div>
                                          </button>
                                          <button
                                            v-else
                                            :style="
                                              getProductButtonCss(pro.style)
                                            "
                                            @click="
                                              goToURLClicked(
                                                product.detail_link,
                                                product.id
                                              )
                                            "
                                            class="ButtonHover"
                                          >
                                            <span v-if="!product.loading">
                                              {{ pro.buttonText }}</span
                                            >
                                            <div
                                              v-else
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                            >
                                              <span class="sr-only"
                                                >Loading...</span
                                              >
                                            </div>
                                          </button>
                                        </div>
                                        <div
                                          v-else
                                          class="d-flex justify-content-center"
                                        >
                                          <div
                                            class="pb-2 w-50"
                                            v-if="
                                              pro.useProductVariant &&
                                              product &&
                                              product.variants
                                            "
                                          >
                                            <VaraintSelectComp
                                              :pro="pro"
                                              :product="product"
                                            />
                                          </div>
                                          <button
                                            v-if="pro.useAddToCart"
                                            :style="
                                              getProductButtonCss(pro.style)
                                            "
                                            :disabled="disableAddtoCartBtn"
                                            class="w-50 ButtonHover"
                                            @click="AddToCart(product, 0)"
                                          >
                                            <span v-if="!product.loading">{{
                                              pro.useAddToCartText ||
                                              "Add to Cart"
                                            }}</span>
                                            <div
                                              v-else
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                            >
                                              <span class="sr-only"
                                                >Loading...</span
                                              >
                                            </div>
                                          </button>
                                          <button
                                            v-else
                                            class="w-50 ButtonHover"
                                            :style="
                                              getProductButtonCss(pro.style)
                                            "
                                            @click="
                                              goToURLClicked(
                                                product.detail_link,
                                                product.id
                                              )
                                            "
                                          >
                                            <span v-if="!product.loading">
                                              {{ pro.buttonText }}</span
                                            >
                                            <div
                                              v-else
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                            >
                                              <span class="sr-only"
                                                >Loading...</span
                                              >
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- Button End -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12" v-else>
                            <carousel
                              class="ProductCarousel"
                              :perPage="1"
                              :paginationEnabled="
                                checkProductLength(getProducts(pro))
                              "
                              :navigationEnabled="
                                checkProductLength(getProducts(pro))
                              "
                            >
                              <slide
                                v-for="(product, index) in getProducts(pro)"
                                :key="index"
                              >
                                <div
                                  class="row m-0 p-0"
                                  :style="
                                    detailProductContainerStyle(
                                      isDesktopView,
                                      showImage(product.image)
                                    ) +
                                    getCardBackground(pro.style) +
                                    getCardBorderCSS(pro.style)
                                  "
                                >
                                  <div
                                    class="p-0 m-0"
                                    v-if="showImage(product.image)"
                                    :class="
                                      getProductCarouselImageClass(
                                        isDesktopView
                                      )
                                    "
                                    :style="
                                      getProductCarouselImageStyle(
                                        isDesktopView
                                      )
                                    "
                                  >
                                    <div
                                      class="w-100 h-100"
                                      style="position: relative"
                                    >
                                      <!-- Product Rank Start -->
                                      <div
                                        style="
                                          position: absolute;
                                          top: 0%;
                                          left: 50%;
                                          transform: translateX(-50%);
                                          z-index: 100;
                                        "
                                        class="d-flex justify-content-center"
                                        v-if="
                                          pro.showProductRank &&
                                          index <= getProducts(pro).length
                                        "
                                      >
                                        <p
                                          style="text-align: center"
                                          :style="getProductRankCss(pro.style)"
                                          v-if="
                                            pro.productRankOption[index] &&
                                            pro.productRankOption[index].text !=
                                              ''
                                          "
                                        >
                                          <span>{{
                                            pro.productRankOption[index].text
                                          }}</span>
                                        </p>
                                      </div>
                                      <!-- Product Rank End -->
                                      <div
                                        v-if="product.image"
                                        style="height: 100%"
                                      >
                                        <div
                                          v-if="
                                            checkFileType(product.image) ==
                                            'video'
                                          "
                                          style="height: 100%"
                                        >
                                          <video
                                            ref="videoRef"
                                            :src="`${product.image}`"
                                            :style="
                                              getDetailProductImageFit(
                                                pro.style
                                              ) +
                                              getDetailProductImageRatio(
                                                pro.style
                                              ) +
                                              getImageborderRadius(pro.style)
                                            "
                                            style="width: 100%; height: 100%"
                                            controls
                                          ></video>
                                        </div>
                                        <div v-else style="height: 100%">
                                          <LazyImage
                                            v-if="
                                              product.image.startsWith('upload')
                                            "
                                            :src="'/' + product.image"
                                            alt=""
                                            :style="
                                              getDetailProductImageFit(
                                                pro.style
                                              ) +
                                              getDetailProductImageRatio(
                                                pro.style
                                              ) +
                                              getImageborderRadius(pro.style)
                                            "
                                          />
                                          <LazyImage
                                            v-else
                                            :src="product.image"
                                            alt=""
                                            :style="
                                              getDetailProductImageFit(
                                                pro.style
                                              ) +
                                              getDetailProductImageRatio(
                                                pro.style
                                              ) +
                                              getImageborderRadius(pro.style)
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="h-100 w-100 d-flex flex-column"
                                    :class="
                                      showImage(product.image)
                                        ? getProductCarouselImageClass(
                                            isDesktopView
                                          )
                                        : 'col-12'
                                    "
                                    :style="
                                      getPagePadding(pro.style, isDesktopView)
                                    "
                                  >
                                    <!-- Title & price -->
                                    <div
                                      v-if="showImage(product.image)"
                                      class="px-3"
                                    >
                                      <p :style="getTitleStyles(pro.style)">
                                        {{
                                          getProductInfoDetail(
                                            pro.useProductVariant,
                                            product,
                                            "title"
                                          )
                                        }}
                                      </p>
                                      <!-- Rating Div start -->
                                      <div
                                        v-if="
                                          product.rating && isJudgeMeInstalled
                                        "
                                        class="Review px-3"
                                        v-html="product.rating"
                                        :style="getPriceColor(pro.style)"
                                      ></div>
                                      <!-- Rating Div end -->
                                    </div>
                                    <div
                                      v-else
                                      class=""
                                      style=""
                                    >
                                      <p :style="getTitleStyles(pro.style)">
                                        {{
                                          getProductInfoDetail(
                                            pro.useProductVariant,
                                            product,
                                            "title"
                                          )
                                        }}
                                      </p>
                                      <!-- Rating Div start -->
                                      <div
                                        v-if="
                                          product.rating && isJudgeMeInstalled
                                        "
                                        class="Review px-3"
                                        v-html="product.rating"
                                        :style="getPriceColor(pro.style)"
                                      ></div>
                                      <!-- Rating Div end -->
                                    </div>
                                    <!-- Product Price , features  -->

                                    <!-- Price Ended -->
                                    <div
                                      class="d-flex flex-column justify-content-between"
                                      :class="
                                        showImage(
                                          getProductInfoDetail(
                                            pro.useProductVariant,
                                            product,
                                            'image'
                                          )
                                        )
                                          ? 'flex-grow-1'
                                          : null
                                      "
                                    >
                                      <!-- Description Start -->
                                      <div
                                        class="flex-grow-1"
                                        v-if="!pro.hideDescription"
                                      >
                                        <div
                                          :style="
                                            limitDeatilProductDescription(
                                              pro.limitDescription,
                                              product.readMore,
                                              isDesktopView,
                                              product.detail_link
                                            )
                                          "
                                          v-if="
                                            showImage(
                                              getProductInfoDetail(
                                                pro.useProductVariant,
                                                product,
                                                'image'
                                              )
                                            )
                                          "
                                        >
                                          <div
                                            v-if="
                                              pro.useBulletDescription &&
                                              product.bullet_description &&
                                              product.bullet_description.length
                                            "
                                          >
                                            <div
                                              v-for="(
                                                bulletPoint, index2
                                              ) in product.bullet_description"
                                              :key="index2"
                                            >
                                              <div
                                                class="d-flex align-items-start mt-1"
                                                :style="
                                                  getBulletStyles(pro.style)
                                                "
                                              >
                                                <div class="mr-3">
                                                  <i
                                                    class="fa"
                                                    :class="
                                                      pro.selectedBulletIcon
                                                    "
                                                  ></i>
                                                </div>
                                                <span
                                                  style="text-align: start"
                                                  >{{ bulletPoint }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="w-100 detailOnlyDescriptionContainer"
                                            v-else
                                            :style="`font-family:${pro.style.fontFamily};`"
                                            v-html="product.description"
                                          ></div>
                                        </div>
                                        <div
                                          :style="
                                            limitDeatilProductDescription(
                                              pro.limitDescription,
                                              product.readMore,
                                              isDesktopView
                                            )
                                          "
                                          v-else
                                          class="d-flex justify-content-center"
                                        >
                                          <div
                                            v-if="
                                              pro.useBulletDescription &&
                                              product.bullet_description &&
                                              product.bullet_description.length
                                            "
                                          >
                                            <div
                                              v-for="(
                                                bulletPoint, index2
                                              ) in product.bullet_description"
                                              :key="index2"
                                            >
                                              <div
                                                class="d-flex align-items-start mt-1"
                                                :style="
                                                  getBulletStyles(pro.style)
                                                "
                                              >
                                                <div class="mr-3">
                                                  <i
                                                    class="fa"
                                                    :class="
                                                      pro.selectedBulletIcon
                                                    "
                                                  ></i>
                                                </div>
                                                <span
                                                  style="text-align: start"
                                                  >{{ bulletPoint }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="w-100 detailOnlyDescriptionContainer"
                                            v-else
                                            :style="`font-family:${pro.style.fontFamily};`"
                                            v-html="product.description"
                                          ></div>
                                        </div>

                                        <div
                                          class=""
                                          v-if="
                                            pro.limitDescription &&
                                            product.description &&
                                            product.description
                                              .split(' ')
                                              .join('').length > 150 &&
                                            !pro.hideDescription &&
                                            false
                                          "
                                        >
                                          <button
                                            class="btn mt-2"
                                            :style="getPriceColor(pro.style)"
                                            @click="
                                              product.readMore =
                                                !product.readMore
                                            "
                                          >
                                            <span v-if="product.readMore">{{
                                              translateText("Less info")
                                            }}</span>
                                            <span v-else
                                              >{{ translateText("More info") }}
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                      <!-- Description End -->
                                      <!-- Button Start -->
                                      <div class="mt-auto">
                                        <div class="">
                                          <div
                                            class="d-flex align-items-center px-3"
                                            :style="
                                              'justify-content:' +
                                              pro.style.textAlign +
                                              ';'
                                            "
                                            v-if="
                                              showImage(
                                                getProductInfoDetail(
                                                  pro.useProductVariant,
                                                  product,
                                                  'image'
                                                )
                                              )
                                            "
                                          >
                                            <p
                                              v-if="
                                                product.price && !isHidePrice
                                              "
                                              :style="getPriceColor(pro.style)"
                                              class="mr-2"
                                            >
                                              <span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'left'
                                                "
                                                :class="
                                                  currencySpace ? 'mr-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >{{
                                                getProductInfoDetail(
                                                  pro.useProductVariant,
                                                  product,
                                                  "price"
                                                )
                                              }}<span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'right'
                                                "
                                                :class="
                                                  currencySpace ? 'mr-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >
                                            </p>
                                            <p
                                              v-if="
                                                product.compare_at_price &&
                                                product.price !==
                                                  product.compare_at_price &&
                                                !isHidePrice
                                              "
                                              :style="getPriceColor(pro.style)"
                                            >
                                              <s>
                                                <span
                                                  v-if="
                                                    CurrencySign &&
                                                    currencyPos == 'left'
                                                  "
                                                  :class="
                                                    currencySpace
                                                      ? 'mr-1'
                                                      : null
                                                  "
                                                  >{{ CurrencySign }}</span
                                                >{{ product.compare_at_price
                                                }}<span
                                                  v-if="
                                                    CurrencySign &&
                                                    currencyPos == 'right'
                                                  "
                                                  :class="
                                                    currencySpace
                                                      ? 'mr-1'
                                                      : null
                                                  "
                                                  >{{ CurrencySign }}</span
                                                >
                                              </s>
                                            </p>
                                          </div>
                                          <div
                                            class="d-flex align-items-center justify-content-center px-3"
                                            v-else
                                          >
                                            <p
                                              v-if="
                                                product.price && !isHidePrice
                                              "
                                              :style="getPriceColor(pro.style)"
                                              class="mr-2"
                                            >
                                              <span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'left'
                                                "
                                                :class="
                                                  currencySpace ? 'mr-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >{{
                                                getProductInfoDetail(
                                                  pro.useProductVariant,
                                                  product,
                                                  "price"
                                                )
                                              }}<span
                                                v-if="
                                                  CurrencySign &&
                                                  currencyPos == 'right'
                                                "
                                                :class="
                                                  currencySpace ? 'mr-1' : null
                                                "
                                                >{{ CurrencySign }}</span
                                              >
                                            </p>
                                            <p
                                              v-if="
                                                product.compare_at_price &&
                                                product.price !==
                                                  product.compare_at_price &&
                                                !isHidePrice
                                              "
                                              :style="getPriceColor(pro.style)"
                                            >
                                              <s>
                                                <span
                                                  v-if="
                                                    CurrencySign &&
                                                    currencyPos == 'left'
                                                  "
                                                  :class="
                                                    currencySpace
                                                      ? 'mr-1'
                                                      : null
                                                  "
                                                  >{{ CurrencySign }}</span
                                                >{{ product.compare_at_price
                                                }}<span
                                                  v-if="
                                                    CurrencySign &&
                                                    currencyPos == 'right'
                                                  "
                                                  :class="
                                                    currencySpace
                                                      ? 'ml-1'
                                                      : null
                                                  "
                                                  >{{ CurrencySign }}</span
                                                >
                                              </s>
                                            </p>
                                          </div>
                                        </div>
                                        <div v-if="product.detail_link">
                                          <div
                                            v-if="
                                              showImage(
                                                getProductInfoDetail(
                                                  pro.useProductVariant,
                                                  product,
                                                  'image'
                                                )
                                              )
                                            "
                                          >
                                            <div
                                              class="pb-2"
                                              v-if="
                                                pro.useProductVariant &&
                                                product &&
                                                product.variants
                                              "
                                            >
                                              <VaraintSelectComp
                                                :pro="pro"
                                                :product="product"
                                              />
                                            </div>

                                            <button
                                              v-if="pro.useAddToCart"
                                              :style="
                                                getProductButtonCss(pro.style)
                                              "
                                              :disabled="disableAddtoCartBtn"
                                              @click="AddToCart(product, 0)"
                                              class="ButtonHover"
                                            >
                                              <span v-if="!product.loading">{{
                                                pro.useAddToCartText ||
                                                "Add to Cart"
                                              }}</span>
                                              <div
                                                v-else
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                              >
                                                <span class="sr-only"
                                                  >Loading...</span
                                                >
                                              </div>
                                            </button>
                                            <button
                                              class="ButtonHover"
                                              v-else
                                              :style="
                                                getProductButtonCss(pro.style)
                                              "
                                              @click="
                                                goToURLClicked(
                                                  product.detail_link,
                                                  product.id
                                                )
                                              "
                                            >
                                              <span v-if="!product.loading">
                                                {{ pro.buttonText }}</span
                                              >
                                              <div
                                                v-else
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                              >
                                                <span class="sr-only"
                                                  >Loading...</span
                                                >
                                              </div>
                                            </button>
                                          </div>
                                          <div
                                            v-else
                                            class="d-flex justify-content-center"
                                          >
                                            <div
                                              class="pb-2 w-50"
                                              v-if="
                                                pro.useProductVariant &&
                                                product &&
                                                product.variants
                                              "
                                            >
                                              <VaraintSelectComp
                                                :pro="pro"
                                                :product="product"
                                              />
                                            </div>
                                            <button
                                              v-if="pro.useAddToCart"
                                              :style="
                                                getProductButtonCss(pro.style)
                                              "
                                              :disabled="disableAddtoCartBtn"
                                              class="w-50 ButtonHover"
                                              @click="AddToCart(product, 0)"
                                            >
                                              <span v-if="!product.loading">{{
                                                pro.useAddToCartText ||
                                                "Add to Cart"
                                              }}</span>
                                              <div
                                                v-else
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                              >
                                                <span class="sr-only"
                                                  >Loading...</span
                                                >
                                              </div>
                                            </button>
                                            <button
                                              v-else
                                              class="w-50 ButtonHover"
                                              :style="
                                                getProductButtonCss(pro.style)
                                              "
                                              @click="
                                                goToURLClicked(
                                                  product.detail_link,
                                                  product.id
                                                )
                                              "
                                            >
                                              <span v-if="!product.loading">
                                                {{ pro.buttonText }}</span
                                              >
                                              <div
                                                v-else
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                              >
                                                <span class="sr-only"
                                                  >Loading...</span
                                                >
                                              </div>
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Button End -->
                                    </div>
                                  </div>
                                </div>
                              </slide>
                            </carousel>
                          </div>
                        </div>
                        <!-- Detail Only View  end-->
                        <!-- Detail- List View start-->

                        <div class="row" v-if="getViewType == 'detailList'">
                          <div class="col-12 mb-3">
                            <div
                              class="row m-0 p-0"
                              :style="
                                detailProductContainerStyle(
                                  isDesktopView,
                                  showImage(
                                    getProductInfoDetail(
                                      pro.useProductVariant,
                                      getFirstProduct,
                                      'image'
                                    )
                                  )
                                ) +
                                getCardBackground(pro.style) +
                                getCardBorderCSS(pro.style)
                              "
                            >
                              <div
                                class="p-0 m-0"
                                v-if="
                                  showImage(
                                    getProductInfoDetail(
                                      pro.useProductVariant,
                                      getFirstProduct,
                                      'image'
                                    )
                                  )
                                "
                                :class="
                                  getProductCarouselImageClass(isDesktopView)
                                "
                                :style="
                                  getProductCarouselImageStyle(isDesktopView)
                                "
                              >
                                <div
                                  class="w-100"
                                  style="height: 100%; position: relative"
                                >
                                  <!-- Product Rank Start -->
                                  <div
                                    style="
                                      position: absolute;
                                      top: -2%;
                                      left: 50%;
                                      transform: translateX(-50%);
                                      z-index: 100;
                                    "
                                    class="d-flex justify-content-center"
                                    v-if="pro.showProductRank"
                                  >
                                    <p
                                      style="text-align: center"
                                      :style="getProductRankCss(pro.style)"
                                      v-if="
                                        pro.productRankOption[0] &&
                                        pro.productRankOption[0].text != ''
                                      "
                                    >
                                      <span>{{
                                        pro.productRankOption[0].text
                                      }}</span>
                                    </p>
                                  </div>
                                  <!-- Product Rank End -->
                                  <div
                                    v-if="
                                      showImage(
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          getFirstProduct,
                                          'image'
                                        )
                                      )
                                    "
                                    class="detailListImage"
                                    style="height: 100%"
                                  >
                                    <div
                                      v-if="
                                        checkFileType(
                                          getProductInfoDetail(
                                            pro.useProductVariant,
                                            getFirstProduct,
                                            'image'
                                          )
                                        ) == 'video'
                                      "
                                      style="height: 100%"
                                    >
                                      <video
                                        ref="videoRef"
                                        :src="`${getProductInfoDetail(
                                          pro.useProductVariant,
                                          getFirstProduct,
                                          'image'
                                        )}`"
                                        :style="
                                          getDetailProductImageFit(pro.style) +
                                          getDetailProductImageRatio(
                                            pro.style
                                          ) +
                                          getImageborderRadius(pro.style)
                                        "
                                        style="width: 100%; height: 100%"
                                        controls
                                      ></video>
                                    </div>
                                    <div v-else style="height: 100%">
                                      <LazyImage
                                        :src="
                                          getProductInfoDetail(
                                            pro.useProductVariant,
                                            getFirstProduct,
                                            'image'
                                          )
                                        "
                                        alt=""
                                        :style="
                                          getDetailProductImageFit(pro.style) +
                                          getDetailProductImageRatio(
                                            pro.style
                                          ) +
                                          getImageborderRadius(pro.style)
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="h-100 w-100 d-flex flex-column"
                                :class="
                                  showImage(
                                    getProductInfoDetail(
                                      pro.useProductVariant,
                                      getFirstProduct,
                                      'image'
                                    )
                                  )
                                    ? getProductCarouselImageClass(
                                        isDesktopView
                                      )
                                    : 'col-12'
                                "
                                :style="
                                  getPagePadding(pro.style, isDesktopView)
                                "
                              >
                                <!-- Title & price -->
                                <div
                                  v-if="
                                    showImage(
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        getFirstProduct,
                                        'image'
                                      )
                                    )
                                  "
                                  class="px-3"
                                >
                                  <p :style="getTitleStyles(pro.style)">
                                    {{
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        getFirstProduct,
                                        "title"
                                      )
                                    }}
                                  </p>
                                  <!-- Rating Div start -->
                                  <div
                                    v-if="
                                      getFirstProduct.rating &&
                                      isJudgeMeInstalled
                                    "
                                    class="Review px-3"
                                    v-html="getFirstProduct.rating"
                                    :style="getPriceColor(pro.style)"
                                  ></div>
                                  <!-- Rating Div end -->
                                </div>
                                <div
                                  v-else
                                  class="d-flex flex-column align-items-center px-3"
                                  style=""
                                >
                                  <p :style="getTitleStyles(pro.style)">
                                    {{
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        getFirstProduct,
                                        "title"
                                      )
                                    }}
                                  </p>
                                  <!-- Rating Div start -->
                                  <div
                                    v-if="
                                      getFirstProduct.rating &&
                                      isJudgeMeInstalled
                                    "
                                    class="Review px-3"
                                    v-html="getFirstProduct.rating"
                                    :style="getPriceColor(pro.style)"
                                  ></div>
                                  <!-- Rating Div end -->
                                </div>
                                <!-- Product Price , features  -->

                                <!-- Price Ended -->
                                <div
                                  class="d-flex flex-column justify-content-between"
                                  :class="
                                    showImage(
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        getFirstProduct,
                                        'image'
                                      )
                                    )
                                      ? 'flex-grow-1'
                                      : null
                                  "
                                >
                                  <!-- Description Start -->
                                  <div
                                    class="flex-grow-1"
                                    v-if="!pro.hideDescription"
                                  >
                                    <div
                                      :style="
                                        limitDeatilProductDescription(
                                          pro.limitDescription,
                                          getFirstProduct.readMore,
                                          isDesktopView,
                                          getFirstProduct.detail_link
                                        )
                                      "
                                      v-if="
                                        showImage(
                                          getProductInfoDetail(
                                            pro.useProductVariant,
                                            getFirstProduct,
                                            'image'
                                          )
                                        )
                                      "
                                    >
                                      <div
                                        v-if="
                                          pro.useBulletDescription &&
                                          getFirstProduct.bullet_description &&
                                          getFirstProduct.bullet_description
                                            .length
                                        "
                                      >
                                        <div
                                          v-for="(
                                            bulletPoint, index2
                                          ) in getFirstProduct.bullet_description"
                                          :key="index2"
                                        >
                                          <div
                                            class="d-flex align-items-start mt-1"
                                            :style="getBulletStyles(pro.style)"
                                          >
                                            <div class="mr-3">
                                              <i
                                                class="fa"
                                                :class="pro.selectedBulletIcon"
                                              ></i>
                                            </div>
                                            <span style="text-align: start">{{
                                              bulletPoint
                                            }}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="w-100"
                                        v-else
                                        v-html="getFirstProduct.description"
                                      ></div>
                                      <!-- <div class="w-100 "  v-if="!pro.hideDescription" v-html="getFirstProduct.description"></div> -->
                                    </div>
                                    <div
                                      :style="
                                        limitDeatilProductDescription(
                                          pro.limitDescription,
                                          getFirstProduct.readMore,
                                          isDesktopView
                                        )
                                      "
                                      v-else
                                      class="d-flex justify-content-center"
                                    >
                                      <div
                                        v-if="
                                          pro.useBulletDescription &&
                                          getFirstProduct.bullet_description &&
                                          getFirstProduct.bullet_description
                                            .length
                                        "
                                      >
                                        <div
                                          v-for="(
                                            bulletPoint, index2
                                          ) in getFirstProduct.bullet_description"
                                          :key="index2"
                                        >
                                          <div
                                            class="d-flex align-items-start mt-1"
                                            :style="getBulletStyles(pro.style)"
                                          >
                                            <div class="mr-3">
                                              <i
                                                class="fa"
                                                :class="pro.selectedBulletIcon"
                                              ></i>
                                            </div>
                                            <span style="text-align: start">{{
                                              bulletPoint
                                            }}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="w-100"
                                        v-else
                                        v-html="getFirstProduct.description"
                                      ></div>
                                      <!-- <div class="w-100 "  v-if="!pro.hideDescription" v-html="getFirstProduct.description"></div> -->
                                    </div>

                                    <div
                                      class=""
                                      v-if="
                                        pro.limitDescription &&
                                        getFirstProduct.description &&
                                        getFirstProduct.description
                                          .split(' ')
                                          .join('').length > 150 &&
                                        !pro.hideDescription &&
                                        false
                                      "
                                    >
                                      <button
                                        class="btn mt-2"
                                        :style="getPriceColor(pro.style)"
                                        @click="
                                          getFirstProduct.readMore =
                                            !getFirstProduct.readMore
                                        "
                                      >
                                        <span v-if="getFirstProduct.readMore">{{
                                          translateText("Less info")
                                        }}</span>
                                        <span v-else
                                          >{{ translateText("More info") }}
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  <!-- Description End -->
                                  <!-- Button Start -->
                                  <div class="mt-auto">
                                    <div class="">
                                      <div
                                        class="d-flex align-items-center px-3"
                                        :style="
                                          'justify-content:' +
                                          pro.style.textAlign +
                                          ';'
                                        "
                                        v-if="
                                          showImage(
                                            getProductInfoDetail(
                                              pro.useProductVariant,
                                              getFirstProduct,
                                              'image'
                                            )
                                          )
                                        "
                                      >
                                        <p
                                          v-if="
                                            getFirstProduct.price &&
                                            !isHidePrice
                                          "
                                          :style="getPriceColor(pro.style)"
                                          class="mr-2"
                                        >
                                          <span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'left'
                                            "
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >{{
                                            getProductInfoDetail(
                                              pro.useProductVariant,
                                              getFirstProduct,
                                              "price"
                                            )
                                          }}<span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'right'
                                            "
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >
                                        </p>
                                        <p
                                          v-if="
                                            getFirstProduct.compare_at_price &&
                                            getFirstProduct.price !==
                                              getFirstProduct.compare_at_price &&
                                            !isHidePrice
                                          "
                                          :style="getPriceColor(pro.style)"
                                        >
                                          <s>
                                            <span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'left'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >{{
                                              getFirstProduct.compare_at_price
                                            }}<span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'right'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >
                                          </s>
                                        </p>
                                      </div>
                                      <div
                                        class="d-flex align-items-center justify-content-center px-3"
                                        v-else
                                      >
                                        <p
                                          v-if="
                                            getFirstProduct.price &&
                                            !isHidePrice
                                          "
                                          :style="getPriceColor(pro.style)"
                                          class="mr-2"
                                        >
                                          <span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'left'
                                            "
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >{{
                                            getProductInfoDetail(
                                              pro.useProductVariant,
                                              getFirstProduct,
                                              "price"
                                            )
                                          }}<span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'right'
                                            "
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >
                                        </p>
                                        <p
                                          v-if="
                                            getFirstProduct.compare_at_price &&
                                            getFirstProduct.price !==
                                              getFirstProduct.compare_at_price &&
                                            !isHidePrice
                                          "
                                          :style="getPriceColor(pro.style)"
                                        >
                                          <s>
                                            <span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'left'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >{{
                                              getFirstProduct.compare_at_price
                                            }}<span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'right'
                                              "
                                              :class="
                                                currencySpace ? 'ml-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >
                                          </s>
                                        </p>
                                      </div>
                                    </div>
                                    <div v-if="getFirstProduct.detail_link">
                                      <div
                                        v-if="
                                          showImage(
                                            getProductInfoDetail(
                                              pro.useProductVariant,
                                              getFirstProduct,
                                              'image'
                                            )
                                          )
                                        "
                                      >
                                        <div
                                          class="pb-2"
                                          v-if="
                                            pro.useProductVariant &&
                                            getFirstProduct &&
                                            getFirstProduct.variants
                                          "
                                        >
                                          <VaraintSelectComp
                                            :pro="pro"
                                            :product="getFirstProduct"
                                          />
                                        </div>

                                        <button
                                          v-if="pro.useAddToCart"
                                          :style="
                                            getProductButtonCss(pro.style)
                                          "
                                          :disabled="disableAddtoCartBtn"
                                          @click="AddToCart(getFirstProduct, 0)"
                                          class="ButtonHover"
                                        >
                                          <span
                                            v-if="!getFirstProduct.loading"
                                            >{{
                                              pro.useAddToCartText ||
                                              "Add to Cart"
                                            }}</span
                                          >
                                          <div
                                            v-else
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span class="sr-only"
                                              >Loading...</span
                                            >
                                          </div>
                                        </button>
                                        <button
                                          v-else
                                          :style="
                                            getProductButtonCss(pro.style)
                                          "
                                          @click="
                                            goToURLClicked(
                                              getFirstProduct.detail_link,
                                              getFirstProduct.id
                                            )
                                          "
                                          class="ButtonHover"
                                        >
                                          <span v-if="!getFirstProduct.loading">
                                            {{ pro.buttonText }}</span
                                          >
                                          <div
                                            v-else
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span class="sr-only"
                                              >Loading...</span
                                            >
                                          </div>
                                        </button>
                                      </div>
                                      <div
                                        v-else
                                        class="d-flex justify-content-center"
                                      >
                                        <div
                                          class="pb-2 w-50"
                                          v-if="
                                            pro.useProductVariant &&
                                            getFirstProduct &&
                                            getFirstProduct.variants
                                          "
                                        >
                                          <VaraintSelectComp
                                            :pro="pro"
                                            :product="getFirstProduct"
                                          />
                                        </div>
                                        <button
                                          v-if="pro.useAddToCart"
                                          :style="
                                            getProductButtonCss(pro.style)
                                          "
                                          :disabled="disableAddtoCartBtn"
                                          class="w-50 ButtonHover"
                                          @click="AddToCart(getFirstProduct, 0)"
                                        >
                                          <span
                                            v-if="!getFirstProduct.loading"
                                            >{{
                                              pro.useAddToCartText ||
                                              "Add to Cart"
                                            }}</span
                                          >
                                          <div
                                            v-else
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span class="sr-only"
                                              >Loading...</span
                                            >
                                          </div>
                                        </button>
                                        <button
                                          v-else
                                          class="w-50 ButtonHover"
                                          :style="
                                            getProductButtonCss(pro.style)
                                          "
                                          @click="
                                            goToURLClicked(
                                              getFirstProduct.detail_link,
                                              getFirstProduct.id
                                            )
                                          "
                                        >
                                          <span v-if="!getFirstProduct.loading">
                                            {{ pro.buttonText }}</span
                                          >
                                          <div
                                            v-else
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span class="sr-only"
                                              >Loading...</span
                                            >
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- Button End -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mb-3">
                            <p :style="getMoreOptionStyles(pro.style)">
                              {{ getMoreOptionText(pro.moreOptionText) }}
                            </p>
                          </div>

                          <div
                            :class="
                              isDesktopView
                                ? `col-${12 / pro.productsPerPage}`
                                : `col-${12 / pro.productsPerPageMobile}`
                            "
                            class="mb-3"
                            v-for="(product, index) in getDetailListProducts"
                            :key="index"
                            v-show="index < displayProductsLength(pro)"
                          >
                            <!-- style="max-width: 211px" -->
                            <div
                              class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
                              :style="
                                getCardBackground(pro.style) +
                                getCardBorderCSS(pro.style) +
                                getPagePadding(pro.style, isDesktopView) +
                                getFontFamily(pro.style) +
                                ListProductMaxWidth(
                                  isDesktopView
                                    ? pro.productsPerPage
                                    : pro.productsPerPageMobile
                                )
                              "
                            >
                              <div
                                style="
                                  position: absolute;
                                  top: -2%;
                                  left: 50%;
                                  transform: translateX(-50%);
                                  z-index: 100;
                                "
                                class="d-flex justify-content-center"
                                v-if="
                                  pro.showProductRank &&
                                  index <= products.length - 1
                                "
                              >
                                <p
                                  style="text-align: center"
                                  :style="getProductRankCss(pro.style)"
                                  v-if="
                                    pro.productRankOption[index + 1] &&
                                    pro.productRankOption[index + 1].text != ''
                                  "
                                >
                                  <span>{{
                                    pro.productRankOption[index + 1].text
                                  }}</span>
                                </p>
                              </div>

                              <div>
                                <div
                                  v-if="
                                    showImage(
                                      getProductInfoDetail(
                                        pro.useProductVariant,
                                        product,
                                        'image'
                                      )
                                    )
                                  "
                                  style="height: 100%"
                                >
                                  <div
                                    v-if="
                                      checkFileType(
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          'image'
                                        )
                                      ) == 'video'
                                    "
                                    :style="ListImageAspectRatio(pro.style)"
                                  >
                                    <video
                                      ref="videoRef"
                                      :src="`${getProductInfoDetail(
                                        pro.useProductVariant,
                                        product,
                                        'image'
                                      )}`"
                                      :style="
                                        getImageFit(pro.style) +
                                        getImageTopBorderCSs(pro.style)
                                      "
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      controls
                                    ></video>
                                  </div>
                                  <div
                                    v-else
                                    :style="ListImageAspectRatio(pro.style)"
                                  >
                                    <LazyImage
                                      v-if="
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          'image'
                                        ).startsWith('upload')
                                      "
                                      :src="
                                        '/' +
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          'image'
                                        )
                                      "
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      :style="
                                        getImageFit(pro.style) +
                                        getImageTopBorderCSs(pro.style)
                                      "
                                    />
                                    <LazyImage
                                      v-else
                                      :src="
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          'image'
                                        )
                                      "
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      :style="
                                        getImageFit(pro.style) +
                                        getImageTopBorderCSs(pro.style)
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="mt-2 flex-grow-1"
                                :style="getPaddingCss(pro.style)"
                              >
                                <div class="d-flex flex-column h-100">
                                  <!-- Product Title -->
                                  <div class="px-3">
                                    <p :style="getTitleStyles(pro.style)">
                                      {{
                                        getProductInfoDetail(
                                          pro.useProductVariant,
                                          product,
                                          "title"
                                        )
                                      }}
                                    </p>

                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        product.rating && isJudgeMeInstalled
                                      "
                                      class="Review px-3"
                                      v-html="product.rating"
                                      :style="getPriceColor(pro.style)"
                                    ></div>
                                    <!-- Rating Div end -->
                                  </div>
                                  <!-- Product Price , features & buy button -->
                                  <div
                                    class="d-flex flex-column justify-content-between flex-grow-1"
                                  >
                                    <div class="">
                                      <div v-if="!pro.hideDescription">
                                        <div
                                          class="d-flex align-items-center mt-1"
                                          :style="
                                            limitProductDescription(
                                              pro.limitDescription,
                                              product.readMore
                                            )
                                          "
                                        >
                                          <div
                                            v-if="
                                              pro.useBulletDescription &&
                                              product.bullet_description &&
                                              product.bullet_description.length
                                            "
                                          >
                                            <div
                                              v-for="(
                                                bulletPoint, index2
                                              ) in product.bullet_description"
                                              :key="index2"
                                            >
                                              <div
                                                class="d-flex align-items-start mt-1"
                                                :style="
                                                  getBulletStyles(pro.style)
                                                "
                                              >
                                                <div class="mr-3">
                                                  <i
                                                    class="fa"
                                                    :class="
                                                      pro.selectedBulletIcon
                                                    "
                                                  ></i>
                                                </div>
                                                <span
                                                  style="text-align: start"
                                                  >{{ bulletPoint }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="w-100"
                                            v-else
                                            :style="`font-family:${pro.style.fontFamily};`"
                                            v-html="product.description"
                                          ></div>
                                        </div>
                                        <div
                                          class=""
                                          v-if="
                                            pro.limitDescription &&
                                            product.description &&
                                            product.description
                                              .split(' ')
                                              .join('').length > 150 &&
                                            false
                                          "
                                        >
                                          <button
                                            class="btn"
                                            :class="
                                              product.readMore ? 'mt-2' : 'mt-2'
                                            "
                                            :style="getPriceColor(pro.style)"
                                            @click="
                                              product.readMore =
                                                !product.readMore
                                            "
                                          >
                                            <span v-if="product.readMore">{{
                                              translateText("Less info")
                                            }}</span>
                                            <span v-else
                                              >{{ translateText("More info") }}
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="mt-3">
                                      <div
                                        class="d-flex align-items-center"
                                        :style="
                                          'justify-content:' +
                                          pro.style.textAlign +
                                          ';'
                                        "
                                      >
                                        <p
                                          v-if="product.price && !isHidePrice"
                                          :style="getPriceColor(pro.style)"
                                          class="m-0 p-0 mr-2"
                                        >
                                          <span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'left'
                                            "
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >{{
                                            getProductInfoDetail(
                                              pro.useProductVariant,
                                              product,
                                              "price"
                                            )
                                          }}<span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'right'
                                            "
                                            :class="
                                              currencySpace ? 'ml-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >
                                        </p>
                                        <p
                                          class="m-0 p-0"
                                          v-if="
                                            product.compare_at_price &&
                                            product.price !==
                                              product.compare_at_price &&
                                            !isHidePrice
                                          "
                                          :style="getPriceColor(pro.style)"
                                        >
                                          <s>
                                            <span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'left'
                                              "
                                              :class="
                                                currencySpace ? 'mr-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >{{ product.compare_at_price
                                            }}<span
                                              v-if="
                                                CurrencySign &&
                                                currencyPos == 'right'
                                              "
                                              :class="
                                                currencySpace ? 'ml-1' : null
                                              "
                                              >{{ CurrencySign }}</span
                                            >
                                          </s>
                                        </p>
                                      </div>

                                      <div
                                        class="mt-3"
                                        v-if="product.detail_link"
                                      >
                                        <div
                                          class="pb-2"
                                          v-if="
                                            pro.useProductVariant &&
                                            product &&
                                            product.variants
                                          "
                                        >
                                          <VaraintSelectComp
                                            :pro="pro"
                                            :product="product"
                                          />
                                        </div>
                                        <button
                                          class="ButtonHover"
                                          v-if="pro.useAddToCart"
                                          :style="
                                            getProductButtonCss(pro.style)
                                          "
                                          :disabled="disableAddtoCartBtn"
                                          @click="AddToCart(product, index)"
                                        >
                                          <span v-if="!product.loading">{{
                                            pro.useAddToCartText ||
                                            "Add to Cart"
                                          }}</span>
                                          <div
                                            v-else
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span class="sr-only"
                                              >Loading...</span
                                            >
                                          </div>
                                        </button>
                                        <button
                                          class="ButtonHover"
                                          v-else
                                          :style="
                                            getProductButtonCss(pro.style)
                                          "
                                          @click="
                                            goToURLClicked(
                                              product.detail_link,
                                              product.id
                                            )
                                          "
                                        >
                                          <span v-if="!product.loading">
                                            {{ pro.buttonText }}</span
                                          >
                                          <div
                                            v-else
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span class="sr-only"
                                              >Loading...</span
                                            >
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Detail- List View end-->
                        <!--  List View start-->

                        <div class="row" v-if="getViewType == 'list'">
                          <ChemicalClient
                            v-if="
                              getPreviewSettings.quizId == chemicalClientQuizId
                            "
                            :apiToken="apiToken"
                            :pro="pro"
                            :isDesktopView="isDesktopView"
                            :pageDesign="pageDesign"
                            :isRanked="true"
                          />

                          <div
                            :class="[
                              isTest && !isDesktopView
                                ? `col-${12 / pro.productsPerPageMobile}`
                                : `col-${
                                    12 / pro.productsPerPageMobile
                                  } col-sm-${12 / pro.productsPerPage}`,
                              products.length <= 2 ? 'mx-auto' : null,
                            ]"
                            class="mb-3"
                            v-for="(product, index) in products"
                            :key="index"
                            v-show="index < displayProductsLength(pro)"
                          >
                            <MultipleViewSingleCard
                              :pro="pro"
                              :index="index"
                              :isTest="isTest"
                              :isDesktopView="isDesktopView"
                              :productsLength="products.length"
                              :product="product"
                              :displayProductsLength="displayProductsLength"
                              :isJudgeMeInstalled="isJudgeMeInstalled"
                              :isHidePrice="isHidePrice"
                              :CurrencySign="CurrencySign"
                              :currencyPos="currencyPos"
                              :currencySpace="currencySpace"
                              :disableAddtoCartBtn="disableAddtoCartBtn"
                              :AddToCart="AddToCart"
                              :goToURLClicked="goToURLClicked"
                              :getProductInfoDetail="getProductInfoDetail"
                              :checkFileType="checkFileType"
                              :getImageFit="getImageFit"
                              :getImageTopBorderCSs="getImageTopBorderCSs"
                              :getTitleStyles="getTitleStyles"
                              :getPriceColor="getPriceColor"
                              :checkToalettsitsarClient="checkToalettsitsarClient"
                            />
                          </div>
                        </div>
                        <!--   List View end-->

                        <!-- Flip CArds Start -->

                        <div class="row" v-if="getViewType == 'flipCards'">
                          <div
                            :class="[
                              isTest && !isDesktopView
                                ? `col-${12 / pro.productsPerPageMobile}`
                                : `col-${
                                    12 / pro.productsPerPageMobile
                                  } col-sm-${12 / pro.productsPerPage}`,
                              products.length <= 2 ? 'mx-auto' : null,
                            ]"
                            class="mb-3"
                            v-for="(product, index) in products"
                            :key="index"
                            v-show="index < displayProductsLength(pro)"
                          >
                            <FlipCards
                              :pro="pro"
                              :isTest="isTest"
                              :index="index"
                              :isDesktopView="isDesktopView"
                              :product="product"
                              :productsLength="products.length"
                              :displayProductsLength="displayProductsLength"
                              :isJudgeMeInstalled="isJudgeMeInstalled"
                              :isHidePrice="isHidePrice"
                              :CurrencySign="CurrencySign"
                              :currencyPos="currencyPos"
                              :currencySpace="currencySpace"
                              :disableAddtoCartBtn="disableAddtoCartBtn"
                              :AddToCart="AddToCart"
                              :goToURLClicked="goToURLClicked"
                              :getProductInfoDetail="getProductInfoDetail"
                              :checkFileType="checkFileType"
                              :getImageFit="getImageFit"
                              :getImageTopBorderCSs="getImageTopBorderCSs"
                              :getTitleStyles="getTitleStyles"
                              :getPriceColor="getPriceColor"
                            />
                          </div>
                        </div>
                        <!-- Flip CArds End -->
                        <!-- Group PRoducts Start -->

                        <div class="row" v-if="getViewType == 'groupProducts'">
                          <GroupProductsComp
                            :pro="pro"
                            :isTest="isTest"
                            :isDesktopView="isDesktopView"
                            :products="products"
                            :displayProductsLength="displayProductsLength"
                            :isJudgeMeInstalled="isJudgeMeInstalled"
                            :isHidePrice="isHidePrice"
                            :CurrencySign="CurrencySign"
                            :currencyPos="currencyPos"
                            :currencySpace="currencySpace"
                            :disableAddtoCartBtn="disableAddtoCartBtn"
                            :AddToCart="AddToCart"
                            :goToURLClicked="goToURLClicked"
                            :getProductInfoDetail="getProductInfoDetail"
                            :checkFileType="checkFileType"
                            :getImageFit="getImageFit"
                            :getImageTopBorderCSs="getImageTopBorderCSs"
                            :getTitleStyles="getTitleStyles"
                            :getPriceColor="getPriceColor"
                          />
                        </div>
                        <!-- Group PRoducts End -->
                      </div>
                      <div v-else>
                        <div class="" v-if="checkSpecialClient() == 'elthV3'">
                          <BaldanCollectionUpdate
                            v-if="isElthClientCollectionUpdate"
                            :key="compKey + 1"
                            :pageHeight="pageDesign.pageStyles.height"
                            :quizId="getPreviewSettings.quizId"
                            :apiToken="apiToken"
                            :headingStyle="pageDesign.blocksArray[0].style"
                            :isDesktopView="isDesktopView"
                            :ProductStyles="pro.style"
                            @changeToUpdateElth="updateElthView"
                            :RoutePage="RoutePage"
                          />

                          <BaldanCollectionCreate
                            v-else
                            :questionAnswer="getSelectedOptions.questionAnswer"
                            :key="compKey"
                            :quizId="getPreviewSettings.quizId"
                            :pageHeight="pageDesign.pageStyles.height"
                            :headingStyle="pageDesign.blocksArray[0].style"
                            :apiToken="apiToken"
                            :ProductStyles="pro.style"
                            :Products="products"
                            :isDesktopView="isDesktopView"
                            :RoutePage="RoutePage"
                          />
                        </div>
                        <div class="" v-if="checkSpecialClient() == 'baldan'">
                          <BaldanCollectionUpdate
                            v-if="isElthClientCollectionUpdate"
                            :key="compKey + 1"
                            :pageHeight="pageDesign.pageStyles.height"
                            :quizId="getPreviewSettings.quizId"
                            :apiToken="apiToken"
                            :headingStyle="pageDesign.blocksArray[0].style"
                            :isDesktopView="isDesktopView"
                            :ProductStyles="pro.style"
                            @changeToUpdateElth="updateElthView"
                            :RoutePage="RoutePage"
                          />

                          <BaldanCollectionCreate
                            v-else
                            :questionAnswer="getSelectedOptions.questionAnswer"
                            :key="compKey"
                            :quizId="getPreviewSettings.quizId"
                            :pageHeight="pageDesign.pageStyles.height"
                            :headingStyle="pageDesign.blocksArray[0].style"
                            :apiToken="apiToken"
                            :ProductStyles="pro.style"
                            :Products="products"
                            :isDesktopView="isDesktopView"
                            :RoutePage="RoutePage"
                          />
                        </div>
                        <div v-if="checkSpecialClient() == 'elth'">
                          <ProductTableClientUpdate
                            v-if="isElthClientCollectionUpdate"
                            :key="compKey"
                            :quizId="getPreviewSettings.quizId"
                            :pageHeight="pageDesign.pageStyles.height"
                            :headingStyle="pageDesign.blocksArray[0].style"
                            :apiToken="apiToken"
                            :ProductStyles="pro.style"
                          />
                          <ProductTableClient
                            v-else
                            :key="compKey + 1"
                            :pageHeight="pageDesign.pageStyles.height"
                            :quizId="getPreviewSettings.quizId"
                            :apiToken="apiToken"
                            :headingStyle="pageDesign.blocksArray[0].style"
                            :Products="products"
                            :ProductStyles="pro.style"
                            @changeToUpdateElth="updateElthView"
                          />
                        </div>
                        <IsraeliChartClient
                          v-if="checkSpecialClient() == 'IsraeliChartClient'"
                        ></IsraeliChartClient>
                        <CracotteClient
                          v-if="checkSpecialClient() == 'CracotteClient'"
                          :Products="products"
                          @addAllToCart="AddAllToCartFunc($event)"
                        ></CracotteClient>
                        <SupplementChartClient
                          v-if="checkSpecialClient() == 'SupplementChartClient'"
                          :Products="products"
                        ></SupplementChartClient>
                        <IkeaFurnitureClient
                          v-if="checkSpecialClient() == 'IkeaFurnitureClient'"
                          :collections="ikeaClientCollections"
                          :apiToken="apiToken"
                        ></IkeaFurnitureClient>
                        <SkincareRoutineClient
                          v-if="checkSpecialClient() == 'SkincareRoutineClient'"
                          :ProductData="pro"
                          :Products="products"
                          :isDesktopView="isDesktopView"
                          :apiToken="apiToken"
                        ></SkincareRoutineClient>
                        <FreightQuottResult
                          :pro="pro"
                          :apiToken="apiToken"
                          v-if="checkSpecialClient() == 'FreightQuot'"
                        >
                        </FreightQuottResult>
                      </div>
                    </div>
                    <div
                      v-if="pro.type == 'button'"
                      :style="
                        'display:flex;justify-content:' +
                        pro.style.position +
                        ';'
                      "
                    >
                      <button
                        class="ButtonHover"
                        v-if="pro.buttonType == 'restart'"
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="restartQuiz"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                        {{ pro.style.alignItems }}
                      </button>
                      <button
                        class="ButtonHover"
                        v-if="pro.buttonType == 'addAllToCart'"
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="handleAddToCart"
                      >
                        <span
                          v-if="!addAllToCartLoading"
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>

                        <div
                          v-else
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>

                      <button
                        class="ButtonHover"
                        v-if="
                          pro.buttonType == 'mailToOwner' ||
                          pro.buttonType == 'mailToCustomer'
                        "
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="sendEmailToLead(pro)"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                      </button>
                      <button
                        class="ButtonHover"
                        v-if="pro.buttonType == 'goToUrl'"
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="goToURLClicked(pro.buttonLink)"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                      </button>
                      <button
                        class="ButtonHover"
                        v-if="pro.buttonType == 'goToCurrentTab'"
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="goToCurrentTabURLClicked(pro.buttonLink)"
                        data="newTab"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                      </button>
                    </div>

                    <SocialLinksComp
                      v-if="pro.type == 'socialLinks'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />
                    <ShareLinksComp
                      v-if="pro.type == 'shareLink'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />

                    <CouponComp
                      v-if="pro.type == 'coupon' && showCouponComp"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                      :pageBgColor="
                        CurrentPage.pageDesign.pageStyles.backgroundColor
                      "
                      :isLive="true"
                    />
                  </div>
                </div>
                <BearWBenefitsBanner
                  v-if="isBearWithBenefitsClient && products.length > 0"
                  class="w-100"
                  :pro="getProductBlock"
                  :products="products"
                  :displayProductsLength="displayProductsLength"
                  :isDesktopView="isDesktopView"
                  :imageCompStyles="getImageCompBlock"
                />
              </div>
              <div v-else>
                <div
                  v-for="(pro, ind) in pageDesign.NoResultBlocksArray"
                  :key="'preview' + ind"
                  :class="[pro.type === 'tooltip' ? 'tooltip-zIndex' : '']"
                >
                  <div
                    id="preview"
                    :style="getHeightWidth(pro.style)"
                    class="content"
                    style="position: relative"
                  >
                    <div
                      v-if="pro.type == 'text'"
                      :style="
                        getColorCss(pro.style) +
                        getPaddingCss(pro.style) +
                        getMarginCss(pro.style, isDesktopView) +
                        getColorWithOpacity(pro.style) +
                        getTextRotate(pro.style) +
                        getLineHeight(pro.style) +
                        getTextAlign(pro.style) +
                        getFontStyle(pro.style, isDesktopView)
                      "
                      v-html="getTextValue(pro.content)"
                    ></div>
                    <div v-if="pro.type == 'imageComp'">
                      <InlineImageComp
                        :pro="pro"
                        :isDesktopView="isDesktopView"
                      />
                    </div>

                    <div
                      v-if="pro.type == 'button'"
                      :style="
                        'display:flex;justify-content:' +
                        pro.style.position +
                        ';'
                      "
                    >
                      <button
                        v-if="
                          pro.buttonType == 'mailToOwner' ||
                          pro.buttonType == 'mailToCustomer'
                        "
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="sendEmailToLead(pro)"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                        {{ pro.style.alignItems }}
                      </button>
                      <button
                        v-if="pro.buttonType == 'restart'"
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="restartQuiz"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                      </button>
                      <button
                        v-if="pro.buttonType == 'goToUrl'"
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="goToURLClicked(pro.buttonLink)"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                      </button>
                      <button
                        v-if="pro.buttonType == 'goToCurrentTab'"
                        :style="getButtonCss(pro.style, isDesktopView)"
                        @click="goToCurrentTabURLClicked(pro.buttonLink)"
                      >
                        <span
                          :style="{
                            opacity: `${pro.style.textOpacity}%`,
                            fontSize: `${pro.style.fontSize}px`,
                          }"
                        >
                          {{ pro.text }}
                        </span>
                      </button>
                    </div>
                    <SocialLinksComp
                      v-if="pro.type == 'socialLinks'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />
                    <ShareLinksComp
                      v-if="pro.type == 'shareLink'"
                      :pro="pro"
                      :isDesktopView="isDesktopView"
                    />
                    <div
                      v-if="pro.type == 'html'"
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getColorWithOpacity(pro.style) +
                        getTextRotate(pro.style) +
                        getTextAlign(pro.style)
                      "
                    >
                      <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-0"
          v-show="showRightImage && pageDesign.pageType !== 'resultPage'"
          :class="[
            {
              'col-12 col-lg-12 col-xs-12': !isDesktopView,
              'image-division col-6': isDesktopView,
            },
          ]"
          :style="
            calculateImageWidth(
              pageDesign.imageBlock,
              isDesktopView,
              IsImageVariableWidthActive
            )
          "
        >
          <div :class="isDesktopView ? 'image-division' : ''">
            <div :style="height100(isDesktopView)">
              <LazyImage
                :style="
                  getUniversalImageStyle(
                    isDesktopView,
                    pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
                  ) +
                  getImageOpacity(pageDesign.imageBlock.style) +
                  getImageRotation(pageDesign.imageBlock.style)
                "
                :class="[
                  `pic-${getSelectedPage.order}`,
                  ImgBlockImageSrc == '/images/default_start_page.png'
                    ? 'default-bg'
                    : '',
                ]"
                :src="MainImgSrc(ImgBlockImageSrc)"
                alt
              />
            </div>
          </div>
        </div>

        <!-- Show Logo Footer -->
      </div>
    </div>
    <div
      v-if="
        isLeadComp &&
        Object.prototype.hasOwnProperty.call(
          getSpecialLeadCompData,
          `pageDesign`
        )
      "
      :class="
        RoutePage == 'QuizFinal'
          ? 'w-100 '
          : checkIsElthBaldanClient
          ? 'w-100'
          : 'col-12 d-flex justify-content-center p-0 '
      "
      style="overflow-x: hidden"
      dir="ltr"
    >
      <div class="specialLEadCompClass">
        <SpecialLeadComp
          :leadHistoryData="getLeadHistoryDataForEndLeadPage"
          :getSelectedPage="getSpecialLeadCompData"
          :isDesktopView="isDesktopView"
          :apiToken="apiToken"
          :data-aos-delay="100"
          :emailInfo="emailInfo"
          :RoutePage="RoutePage"
          @back="backFromLead"
          :getIsAnimationEnabled="getIsAnimationEnabled"
          :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
          :data-aos-duration="getAnimationSpeedinMillis"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VS2 from "vue-script2";

Vue.use(VS2);
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
import { Carousel, Slide } from "vue-carousel";
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: [
    "isDesktopView",
    "apiToken",
    "isTest",
    "animationType",
    "RoutePage",
    "resultPreviewProducts",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    
  ],

  mixins: [cssFunctionMixin],
  components: {
    Carousel,
    Slide,
    SpecialLeadComp: () =>
      import(
        /* webpackChunkName: "HtmlElement" */ "./specialClients/Bauerfeind/SpecialLeadComp.vue"
      ),
    HtmlElement: () =>
      import(/* webpackChunkName: "HtmlElement" */ "./HtmlElement.vue"),
    CouponComp: () =>
      import(/* webpackChunkName: "CouponComp" */ "./CouponComp.vue"),
    ProductTableClient: () =>
      import(
        /* webpackChunkName: "ProductTableClient" */ "../quizPreviewComp/ProductTableClient.vue"
      ),
    ChemicalClient: () =>
      import(
        /* webpackChunkName: "ChemicalClient" */ "../quizPreviewComp/specialClients/ChemicalClient.vue"
      ),
    ProductTableClientUpdate: () =>
      import(
        /* webpackChunkName: "ProductTableClientUpdate" */ "../quizPreviewComp/ProductTableClientUpdate.vue"
      ),
    VaraintSelectComp: () =>
      import(
        /* webpackChunkName: "VaraintSelectComp" */ "../quizPreviewComp/VaraintSelectComp.vue"
      ),
    IsraeliChartClient: () =>
      import(
        /* webpackChunkName: "IsraeliChartClient" */ "../quizPreviewComp/specialClients/IsraeliChartClient.vue"
      ),
    CracotteClient: () =>
      import(
        /* webpackChunkName: "CracotteClient" */ "../quizPreviewComp/specialClients/CracotteClient.vue"
      ),
    SupplementChartClient: () =>
      import(
        /* webpackChunkName: "SupplementChartClient" */ "../quizPreviewComp/specialClients/SupplementChartClient.vue"
      ),
    IkeaFurnitureClient: () =>
      import(
        /* webpackChunkName: "IkeaFurnitureClient" */ "../quizPreviewComp/specialClients/IkeaFurnitureClient.vue"
      ),
    SkincareRoutineClient: () =>
      import(
        /* webpackChunkName: "SkincareRoutineClient" */ "../quizPreviewComp/specialClients/SkincareRoutineClient.vue"
      ),
    MultipleViewSingleCard: () =>
      import(
        /* webpackChunkName: "MultipleViewSingleCard" */ "./components/ResultPage/MultipleViewSingleCard.vue"
      ),
    FlipCards: () =>
      import(
        /* webpackChunkName: "FlipCards" */ "./components/ResultPage/FlipCards.vue"
      ),
    GroupProductsComp: () =>
      import(
        /* webpackChunkName: "GroupProductsComp" */ "./components/ResultPage/GroupProductsComp.vue"
      ),
    BaldanCollectionCreate: () =>
      import(
        /* webpackChunkName: "BaldanCollectionCreate" */ "./specialClients/ElethXBaldan/BaldanCollectionCreate.vue"
      ),
    BaldanCollectionUpdate: () =>
      import(
        /* webpackChunkName: "BaldanCollectionUpdate" */ "./specialClients/ElethXBaldan/BaldanCollectionUpdate.vue"
      ),
    SocialLinksComp: () =>
      import(/* webpackChunkName: "SocialLinksComp" */ "./SocialLinksComp.vue"),
    ShareLinksComp: () =>
      import(/* webpackChunkName: "ShareLinksComp" */ "./ShareLinksComp.vue"),
    LoaderMain: () =>
      import(
        /* webpackChunkName: "LoaderMain" */ "../../components/LoaderComps/LoaderMain.vue"
      ),
      ColonBroomLoading: () =>
      import(
        /* webpackChunkName: "ColonBroomLoading" */ "./specialClients/ColonBroom/ColonBroomLoading.vue"
      ),
    FreightQuottResult: () =>
      import(
        /* webpackChunkName: "FreightQuottResult" */ "./specialClients/FreightQuottResult.vue"
      ),

    InlineImageComp: () =>
      import(
        /* webpackChunkName: "InlineImageComp" */ "./components/InlineImageComp.vue"
      ),
    BearWBenefitsBanner: () =>
      import(
        /* webpackChunkName: "BearWBenefitsBanner" */ "./specialClients/BearWBenefitsBanner.vue"
      ),
  },
  data() {
    return {
      emailInfo: {},
      isLeadComp: false,
      firstObjectWithValueLeads: {},
      isChartUser: false,
      activeSideBar: "pageStyle",
      pageDesign: {},
      count: 0,
      SelectedValue: "",
      isLoading: false,
      products: [],
      name: "",
      email: "",
      scoreStatus: null,
      score: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      isProductTableUser: false,
      compKey: 0,
      //  allowedAuthUser:['1386','2492'],

      isJudgeMeInstalledInQuiz: false,
      currentAddToCartProductIndex: null,

      ikeaClientCollections: [],
      sampleElthProduct: [
        {
          id: 101740,
          rating: "",
          data_order: 0,
          title: "Absolute Natural Lipstick  - Rossetto nude",
          vendor: "Gil CagnÃ©",
          price: 18,
          sku: "GCF110/101",
          product_type: "Rossetti",
          compare_at_price: null,
          detail_link:
            "https://beauty-store-wl.myshopify.com/products/absolute-natural-lipstick",
          image:
            "https://cdn.shopify.com/s/files/1/0724/0574/5977/products/GCF110-101.jpg?v=1682532798",
          external_id: "8259311763769",
          variant_id: 45041607082297,
          bullet_description: null,
          tags: [""],
          variants: [],
          selectedVariant: "",
          loading: false,
          description:
            "<p>Rossetto altamente pigmentato e setoso con un effetto finale cremoso e luminoso. Ha una formula no transfer, ma 'pulita', con il 95% di ingredienti naturali. Dona alle labbra un comfort assoluto grazie alla texture arricchita con olio di jojoba emolliente e vitamina E, caratteristica rara per un rossetto a lunga tenuta.<br> Colore neutro, adatto a tutti i fototipi. Il particolare taglio dello stick facilita lâ€™applicazione del rossetto, perchÃ© permette di seguire comodamente il volume e la linea delle labbra. Applica il prodotto dallâ€™interno verso lâ€™esterno per il labbro inferiore, viceversa per quello superiore.</p>\n<h4>INGREDIENTI</h4>\n<p>â€¢ricinus communis seed oil â€¢caprylic/capric triglyceride â€¢oryza sativa bran cera â€¢candelilla cera â€¢copernicia cerifera cera â€¢aroma â€¢simmondsia chinensis seed oil â€¢olea europaea fruit oil â€¢tocopheryl acetate â€¢benzyl alcohol â€¢tocopherol â€¢dehydroacetic acid. <br></p>\n<p>(+/-) may contain: CI 77891 â€¢CI 77491 â€¢CI 77492 â€¢CI 77499 â€¢CI 15850 (red 7 lake) â€¢CI 42090 â€¢CI 15850 (red 6 lake) â€¢CI 45410 â€¢CI 19140 â€¢CI 15850 (red 6).</p>",
          repeatedTime: 0,
        },
      ],
      addAllToCartLoading: false,
      audioContext: null,
      audioBuffer: null,
      audioSource: null,
      audioPlayed: false,
      source:null,
      gainNode:null
    };
  },
  methods: {
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },
    ...mapMutations([`setSavedLeadID`]),
    backFromLead() {
      this.isLeadComp = false;
    },
    checkSpecialClient() {
      let client = "";
      let FRIEGHTQUOT = this.FrieghtQuotClient;
      if (
        this.getPreviewSettings.quizId == this.getEnv?.VUE_APP_ITALIAN_QUIZID ??
        null
      )
        client = "baldan";
      else if (
        this.getPreviewSettings.quizId == this.getEnv?.VUE_APP_ELTH_QUIZID ??
        null
      )
        client = "elth";
      else if (
        this.getPreviewSettings.quizId == this.getEnv?.VUE_APP_ELTH_V3_QUIZID ??
        null
      )
        client = "elthV3";
      else if (
        this.getPreviewSettings.quizId ==
          this.getEnv?.VUE_APP_ISRAELI_CHART_QUIZID ??
        null
      )
        client = "IsraeliChartClient";
      else if (
        this.getPreviewSettings.quizId ==
          this.getEnv?.VUE_APP_ISRAELI_CHART_2_QUIZID ??
        null
      )
        client = "IsraeliChartClient";
      else if (
        this.getPreviewSettings.quizId ==
          this.getEnv?.VUE_APP_CRACOTTE_QUIZID ??
        null
      )
        client = "CracotteClient";
      else if (
        this.getPreviewSettings.quizId ==
          this.getEnv?.VUE_APP_SUPPLEMENT_CHART_QUIZID ??
        null
      )
        client = "SupplementChartClient";
      else if (
        this.getPreviewSettings.quizId ==
          this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID ??
        null
      )
        client = "IkeaFurnitureClient";
      else if (
        this.getPreviewSettings.quizId ==
          this.getEnv?.VUE_APP_SKINCARE_ROUTINE_QUIZID ??
        null
      )
        client = "SkincareRoutineClient";
      else if (this.getPreviewSettings.quizId == FRIEGHTQUOT[0]?.quizId)
        client = "FreightQuot";
      return client;
    },

    productsWithoutSku(products) {
      this.products = products;
    },
    async increaseAddToCartCount(products, userType, AddToCartMode) {
      let incCountPayload = {
        api_token: this.apiToken,
        quizId:
          this.getLeadObjfromVuex.quiz_id || this.getPreviewSettings.quizId,
        leadId: this.getLeadObjfromVuex.id,
        quiz_analytic_id: this.getLeadObjfromVuex?.quiz_analytic_id,
      };

      if (userType == "shopify") {
        if (AddToCartMode == "addAllProducts") {
          incCountPayload["variantIds"] = products.map((x) => x.id);
        } else {
          incCountPayload["variantIds"] = [products];
        }
      } else {
        incCountPayload["productIds"] = [products];
      }

      try {
        const response = await axios.post(
          `/api/product/analytics/increase-cart-count`,
          incCountPayload
        );
        return response.status == 200;
      } catch (error) {
        if (error) throw error;
      }
    },

    handleAddToCart() {
  const {
    productsPerPage,
    rows,
    productsPerPageMobile,
    mobileRows
  } = this.getProductBlock;

  const totalProducts = this.isDesktopView
    ? productsPerPage * rows
    : productsPerPageMobile * (mobileRows || rows);

  const productsToAdd = totalProducts > 0
    ? this.products.slice(0, totalProducts)
    : this.products;

  this.AddAllToCartFunc(productsToAdd);
},

    async AddAllToCartFunc(products) {
      this.addAllToCartLoading = true;
      let data = {
        items: products,
        quiz_key: this.apiToken,
      };
      try {
        await this.increaseAddToCartCount(
          products,
          "shopify",
          "addAllProducts"
        );

        window.parent.postMessage(JSON.stringify(data), "*");
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.addAllToCartLoading = false;
      }
    },
    async AddToCart(product, index) {
      this.products[index].loading = true;

      this.currentAddToCartProductIndex = index;
      let data = {
        quiz_key: this.apiToken,
        items: [
          {
            id: product.variant_id,
            quantity: 1,
          },
        ],
      };
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        data.items[0].id = product.selectedVariant.external_id;
      }

      try {
        await this.increaseAddToCartCount(
          product.variant_id,
          "shopify",
          "addSingleProducts"
        );
        window.parent.postMessage(JSON.stringify(data), "*");
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
      // finally {
      //   this.products[index].loading = false;
      // }
    },
    // variant MEthods
    getSingleProductImage(product) {
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        if (product.selectedVariant.image) {
          return product.selectedVariant.image;
        } else {
          return product.image;
        }
      } else {
        return product.image;
      }
    },
    getSingleProductTitle(product) {
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        return product.selectedVariant.title;
      } else {
        return product.title;
      }
    },
    getSingleProductPrice(product) {
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        return product.selectedVariant.price;
      } else {
        return product.price;
      }
    },

    getProductInfoDetail(isProductVarient, product, value) {
      let result;

      if (isProductVarient) {
        result =
          product.selectedVariant && product.selectedVariant !== ""
            ? product.selectedVariant[value] || product[value]
            : product[value];
      } else {
        result = product[value];
      }

      return value === "price" ? this.getformattedPrice(result) : result;
    },

    getformattedPrice(price) {
      const floatPrice = parseFloat(price);
      if (Number.isInteger(floatPrice)) {
        return floatPrice;
      } else {
        return floatPrice.toFixed(2);
      }
    },

    // Varient Method End

    updateProductVariant(product) {
      const selectedVariant = product.variants.find(
        (variant) => variant.title === product.selectedVariant.title
      );
      product.title = selectedVariant.title;
      product.price = selectedVariant.price;
      product.image = selectedVariant.image;
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    showImage(image) {
      return !(
        image.includes("default_start_page.png") ||
        image.includes("/images/image_placeholder.png")
      );
    },

    getProducts(block) {
      if (block.limitProduct) {
        let Products = [...this.products];
        if (
          block.limitProductLength &&
          block.limitProductLength < Products.length
        ) {
          let newArr = [];
          for (let i = 0; i < block.limitProductLength; i++) {
            newArr.push(Products[i]);
          }
          return newArr;
        } else {
          return Products;
        }
      } else {
        return this.products;
      }
    },
    checkProductLength(arr) {
      return arr.length > 1 ? true : false;
    },
    getMoreOptionText(value) {
      if (value) {
        return value;
      } else {
        return "More options";
      }
    },
    ...mapActions(["updateProductArray", "updateResultPreviewPageType"]),
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; " +
        this.getQuizPageHeight(
          this.pageDesign.pageStyles.height,
          this.RoutePage,
          this.isTest
        );

      if (this.isChartUser) design = design + "margin-top:0px !important;";

      if (this.RoutePage == "QuizPreview")
        design = design + "max-height:80dvh;";

      return design;
    },
    getResultPageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
    getbackgroundImage(IsImageAdded, property, imageAlign) {
      let backgroundPos;
      if (property.style.xCord && property.style.xCord) {
        backgroundPos = `background-position:${property.style.xCord}% ${property.style.yCord}%;`;
      } else {
        backgroundPos = `background-position:50% 50%;`;
      }

      if (
        this.pageDesign.pageType == "resultPage" &&
        IsImageAdded &&
        property.src !== ""
      ) {
        let filter;
        if (property.style.imageOpacity >= 0) {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          }) contrast(${2 - property.style.imageOpacity / 100}) ;`;
        } else {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          })  ;`;
        }

        return `background-image: url(${property.src}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos} ${filter}`;
      } else if (
        this.pageDesign.pageType == "noResultPage" &&
        IsImageAdded &&
        property.noResultSrc !== "" &&
        imageAlign == "no"
      ) {
        let filter;
        if (property.style.imageOpacity >= 0) {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          }) contrast(${2 - property.style.imageOpacity / 100}) ;`;
        } else {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          })  ;`;
        }

        return `background-image: url(${property.noResultSrc}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos} ${filter}`;
      }
    },
    getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${
          property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    getFontFamily(property) {
      return "font-family:" + property.fontFamily + ";";
    },
    getCardBackground(property) {
      return "background-color:" + property.backgroundColor + ";";
    },
    getPriceColor(property) {
      return `color:${property.priceColor};text-align:${property.textAlign};font-family:${property.fontFamily};`;
    },
    getCardBorderCSS(property) {
      return (
        " border-radius:" +
        property.borderRadius +
        "px; border:" +
        property.borderSize +
        "px solid " +
        property.borderColor +
        ";"
      );
    },
    getPagePadding(property, isDesktopView) {
      const getPaddingValue = (desktopValue, mobileValue) =>
        isDesktopView || mobileValue === undefined ? desktopValue : mobileValue;

      const paddingTop = getPaddingValue(
        property.pagePaddingTop,
        property.pageMobilePaddingTop
      );
      const paddingRight = getPaddingValue(
        property.pagePaddingRight,
        property.pageMobilePaddingRight
      );
      const paddingBottom = getPaddingValue(
        property.pagePaddingBottom,
        property.pageMobilePaddingBottom
      );
      const paddingLeft = getPaddingValue(
        property.pagePaddingLeft,
        property.pageMobilePaddingLeft
      );

      return `; text-align: ${property.textAlign}; padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px; `;
    },
    getImageTopBorderCSs(property) {
      return (
        " border-top-left-radius:" +
        property.borderRadius +
        "px; border-top-right-radius:" +
        property.borderRadius +
        "px ; "
      );
    },
    getImageRatio(property) {
      let imageRatioCSS;
      if (property.imageRatio === "square") {
        imageRatioCSS = "";
      } else if (property.imageRatio === "potrait") {
        imageRatioCSS = `position:absolute;height:100%; width:66%; margin:auto; left:0; right:0;`;
      } else {
        imageRatioCSS = `position:absolute; width:100%; height:66%; margin:auto; top:0; bottom:0;`;
      }

      return " width: 100% " + ";" + "height: 150px" + ";" + imageRatioCSS;
    },
    getImageFit(property) {
      let imageFitCSS;
      if (property.imageFit === "Fit") {
        imageFitCSS = "object-fit: contain" + ";";
      } else if (property.imageFit === "Fill") {
        imageFitCSS = "object-fit: fill" + ";";
      }
      return imageFitCSS;
    },

    displayProductsLength(element) {
      let totalProducts;
      if (this.isDesktopView) {
        totalProducts = element.productsPerPage * element.rows;
      } else {
        totalProducts = element.mobileRows
          ? element.productsPerPageMobile * element.mobileRows
          : element.productsPerPageMobile * element.rows;
      }
      return totalProducts;
    },
    async goToURLClicked(url, productId = null) {
      if (productId && !this.isTest) {
        const index = this.products.findIndex((x) => x.id === productId);
        if (index > -1) {
          this.products[index].loading = true;

          try {
            await this.increaseAddToCartCount(
              productId,
              "web",
              "addSingleProducts"
            );
          } catch (error) {
            this.products[index].loading = false;
            throw error;
          } finally {
            this.products[index].loading = false;
          }
        }
      }

      if (url) {
        const pattern = /^(http|https|ftp):\/\//;
        const formattedUrl = pattern.test(url) ? url : `https://${url}`;
        const openUrl = (url) => {
          window.top.location.href = url;
        };

        const newTab = window.open(formattedUrl, "_blank");
        if (!newTab || newTab.closed || typeof newTab.closed == "undefined") {
          openUrl(formattedUrl);
        }
        window.parent.postMessage(
            {
                type: "navigate",
                url: formattedUrl,
            })
      }
    },
    sendEmailToLead(pro) {
      const { buttonType, email } = pro;

      this.emailInfo = {
        mail_to: buttonType == "mailToOwner" ? "owner" : "customer",
        owner_email: email ? email : "",
      };
      this.firstObjectWithValueLeads = this.getTotalPreviewPages.find(
        (obj) => obj.value == "leadPage"
      );
      this.isLeadComp = true;
    },
    goToCurrentTabURLClicked(url) {
      let URL = url;
      let pattern = /^((http|https|ftp):\/\/)/;
      if (URL !== "") {
        if (!pattern.test(URL)) {
          URL = "http://" + URL;
        }

        window.top.location.href = URL;
      }
    },

    async submitAnswer() {
      this.isLoading = true;
      const QuizID = this.getPreviewSettings.quizId;

      if (this.allowedAuthUser.includes(QuizID.toString())){
        this.isProductTableUser = true;
      }
        

      try {
        let lang=this.$route.query?.lang
        let data = {
          quizId: QuizID,
          questionAnswer: this.getSelectedOptions.questionAnswer,
          api_token: this.apiToken,
          leadId: this.getLeadObjfromVuex?.id,
          quiz_analytic_id: this.getLeadObjfromVuex?.quiz_analytic_id,
          tag: "",
          rangeSliderInputs: this.getSliderValues,
          lang:lang
        };
        if (this.isTest) {
          data["isTest"] = true;
        }

        await axios
          .post(`/api/loadProducts`, data)
          .then((response) => {
            if (response.status == 200) {
              if (this.getPreviewSettings.quizId == 2490) this.saveFinalLead();
              this.scoreStatus = response.data.data.count_score_status;
              if (this.scoreStatus || this.scoreStatus == 1) {
                this.score = response.data.data.scores;
              }

              if (
                this.getPreviewSettings.quizId ==
                  this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID ??
                null
              ) {
                this.ikeaClientCollections = response.data.data.collections;
              }

              if (response.data.data.products.length) {
                let products = response.data.data.products.map((data) => {
                  return {
                    ...data,
                    readMore: false,
                  };
                });
                window.quizell.products = products;
                this.products = products;
              } else {
                this.pageDesign.pageType = "noResultPage";
              }

              if (
                this.getSavedLeadID &&
                this.getPreviewSettings.quizId !=
                  parseInt(process.env.VUE_APP_BAUERFEIND_QUIZID)
              ) {
                this.saveLeadHistory();
              }
              if(this.isColonBroomSingleQuiz.quizId==this.getSelectedPage.quiz_id)
              {
                setTimeout(()=>{
                  window.top.location.href = `https://shop.colonbroom.com/pages/summary?code=${this.getLeadObjfromVuex.result_key}`;
                },6000)
                return 
              }
              this.isLoading = false;
            }
          })
          .catch((error) => {
            if (error) {
              this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
            }
            throw error;
          });
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    async saveFinalLead() {
      try {
        this.nextBtnSpinner = true;
        let data = {
          quizId: this.getPreviewSettings.quizId,
          // leadData: JSON.stringify(allInput),
          leadData: JSON.stringify(this.getLeadObjfromVuex),
          api_token: this.apiToken,
          quiz_analytic_id: this.getLeadObjfromVuex.quiz_analytic_id,
        };
        if (this.isTest) {
          data["isTest"] = true;
        }

        const response = await axios.post(`/api/saveFinalLead`, data);
        if (response.status == 200) {
          this.setSavedLeadID(response.data.data);
          return true;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    async saveLeadHistory(tagText = "") {
      if (!this.getSavedLeadID.id) {
        if (this.getPreviewSettings.single_step_status) {
          await this.saveFinalLead();
        } else {
          return;
        }
      }

      const QuizID = this.getPreviewSettings.quizId;
      try {
        let data = {
          quizId: QuizID,
          leadId: this.getSavedLeadID.id,
          // products: JSON.stringify(this.products),
          products: JSON.stringify(this.getProducts(this.getProductBlock)),
          questionAnswer: JSON.stringify(
            this.getSelectedOptions.questionAnswer
          ),
          api_token: this.apiToken,
          quiz_analytic_id: this.getLeadObjfromVuex.quiz_analytic_id,
          tag: tagText,
        };

        if (this.isTest) {
          data["isTest"] = true;
        }
        if (this.scoreStatus && this.score != "") {
          data["total_score"] = this.score;
        }
        await axios.post(`/api/saveLeadHistory`, data);
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    restartQuiz() {
      window.location.href = this.$router.history.current.fullPath;
    },
    async getUpdateCollectionData() {
      try {
        let data = {
          email:
            localStorage.getItem("already_record_email") ||
            this.$route.query.email,
          api_token: this.apiToken,
        };

        const response = await axios.post(
          `/api/custom/collection/detail`,
          data
        );

        if (response.status == 200) {
          this.products = response.data.data.collection_products;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    updateElthView() {
      this.compKey++;
      this.getUpdateCollectionData();
    },
    async getAppInstalledByQuiz(handleKey) {
      try {
        let data = {
          handle_key: handleKey,
          quiz_id: localStorage.getItem("QuizID"),
        };

        const response = await axios.get(`/getAppInstalledToQuiz`, {
          params: data,
        });
        if (response.status == 200 && response.data.ok) {
          this.isJudgeMeInstalledInQuiz = response.data.installed;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
    setupAudio() {
      const soundURL='https://quizell.nyc3.cdn.digitaloceanspaces.com/audios/round-endresult.mp3'
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.source = this.audioContext.createBufferSource();
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.value = 0.2; 
      fetch(soundURL)
        .then(response => response.arrayBuffer())
        .then(data => this.audioContext.decodeAudioData(data))
        .then(buffer => {
          this.source.buffer = buffer;
          this.source.connect(this.gainNode);
          this.source.loop = true;
          this.gainNode.connect(this.audioContext.destination);
          this.source.start();
        })
        .catch(error => {
          console.error('Error playing audio:', error);
        });
    },
    stopAudio() {
      console.log('inner stop method')
      if (this.source) {
        this.source.stop();
        this.source.disconnect();
        this.source = null;
      }
      if (this.gainNode) {
        this.gainNode.disconnect();
        this.gainNode = null;
      }
      if (this.audioContext) {
        this.audioContext.close().then(() => {
          this.audioContext = null;
        }).catch(error => {
          console.error('Error closing audio context:', error);
        });
      }
    },
  },
  computed: {
    TOALETTSTSAR_CLIENT() {
      return this.getEnv?.VUE_APP_TOALETTSTSAR_CLIENT??null;
    },
    checkToalettsitsarClient() {
      if(!this.TOALETTSTSAR_CLIENT)
      return false
      return (
        this.TOALETTSTSAR_CLIENT.quiz_ids.some(
          (id) => id == this.getPreviewSettings.quizId
        )
      );
    },
    isColonBroomSingleQuiz(){
      let isColonBroom
      if(this.getEnvConst?.COLONBROOM)
      isColonBroom=JSON.parse(this.getEnvConst?.COLONBROOM)
      return isColonBroom
    },
   dehwaQuizEnv(){
      return process.env?.VUE_APP_SLIDER_DEWHA_QUIZID;
    },
    isResultView() {
      return this.products.length;
    },
    getViewType() {
      const { useSeperateMobileViewType, viewType, mobileViewType } =
        this.getProductBlock;

      return useSeperateMobileViewType && !this.isDesktopView && mobileViewType
        ? mobileViewType
        : viewType;
    },

    getImageCompBlock() {
      if (this.isBearWithBenefitsClient) {
        const index = this.CurrentPage.pageDesign.blocksArray.findIndex(
          (block) => block.type == "imageComp"
        );

        return index > -1 ? this.pageDesign.blocksArray[index] : null;
      }
      return null;
    },

    FrieghtQuotClient() {
      let env = this.getEnv?.FRIEGHTQUOT_CLIENT ?? [];
      if (!Array.isArray(env)) {
        // eslint-disable-next-line no-useless-escape
        env = env.replace(/([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g, '$1"$3":');
        return env ? JSON.parse(env) : [];
      }
      return env;
    },
    getSpecialLeadCompData() {
      return this.firstObjectWithValueLeads;
    },
    getLeadHistoryDataForEndLeadPage() {
      return {
        products: JSON.stringify(this.products),
        questionAnswer: JSON.stringify(this.getSelectedOptions.questionAnswer),
      };
    },
    checkIsElthBaldanClient() {
      if (this.checkSpecialClient() == "baldan") return true;
      if (this.checkSpecialClient() == "elth") return true;
      if (this.checkSpecialClient() == "elthV3") return true;

      return false;
    },
    getMainDivPadding() {
      return this.getPreviewSettings.quizId != this.baldanClientId
        ? this.getMainPaddingCss(this.pageDesign.pageStyles, this.isDesktopView)
        : "padding:0px 0px;";
    },
    getResultViewType() {
      const blockArray = this.pageDesign.blocksArray;
      return blockArray[this.getProductArrayIndex].viewType;
    },
    isSpecialClient() {
      const clientsArray = this.getEnv?.VUE_APP_SPECIAL_CLIENTS ?? [];
      return clientsArray.includes(this.getPreviewSettings.quizId);
    },

    disableAddtoCartBtn() {
      return typeof this.currentAddToCartProductIndex == "number"
        ? true
        : false;
    },
    getScriptValue() {
      if (this.pageDesign.pageStyles)
        return this.pageDesign.pageStyles.jsCode
          ? this.pageDesign.pageStyles.jsCode
          : "";
      else return "";
    },
    CurrentPageType() {
      if (this.products.length) return "resultPage";
      return "noResultPage";
    },
    isNoResultSrcPresent() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.pageDesign.imageBlock,
          "noResultSrc"
        )
      )
        return true;
      return false;
    },

    ImgBlockImageSrc() {
      const selectImage = (defaultSrc, mobileSrc) => {
        if (this.isDesktopView) {
          return defaultSrc;
        }
        return this.pageDesign.imageBlock.useMobileImage
          ? mobileSrc
          : defaultSrc;
      };

      if (
        this.CurrentPageType === "resultPage" &&
        this.pageDesign.imageBlock.src !== ""
      ) {
        return selectImage(
          this.pageDesign.imageBlock.src,
          this.pageDesign.imageBlock.useMobileImageSrc
        );
      }

      if (
        this.CurrentPageType === "noResultPage" &&
        this.isNoResultSrcPresent &&
        this.pageDesign.imageBlock.noResultSrc !== ""
      ) {
        return selectImage(
          this.pageDesign.imageBlock.noResultSrc,
          this.pageDesign.imageBlock.useMobileImageSrc
        );
      }

      return "";
    },
    isJudgeMeInstalled() {
      return this.isJudgeMeInstalledInQuiz || this.isJudgeMeInstalledInQuiz == 1
        ? true
        : false;
    },
    isAddToCartActive() {
      if (typeof this.getPreviewSettings.add_to_cart !== "undefined") {
        return this.getPreviewSettings.add_to_cart;
      } else {
        return false;
      }
    },
    isElthClientCollectionUpdate() {
      let isRecordPresent = localStorage.getItem("already_record_status");
      let isElthUserEmailPresent = localStorage.getItem("already_record_email");
      if (isRecordPresent && isElthUserEmailPresent) {
        return true;
      } else {
        return false;
      }
    },
    isElthClientQuizContinue() {
      let isCollectionQuizContinue = localStorage.getItem(
        "already_record_quiz_continue"
      );

      if (isCollectionQuizContinue) {
        return true;
      } else {
        return false;
      }
    },

    getFirstProduct() {
      if (this.products.length > 0) {
        return this.products[0];
      } else {
        return {};
      }
    },
    getDetailListProducts() {
      if (this.products.length > 1) {
        const newArr = [...this.products];
        newArr.shift();
        return newArr;
      } else {
        return [];
      }
    },
    getImageAlign() {
      if (
        this.pageDesign.pageStyles.useMobileImageAlign &&
        !this.isDesktopView
      ) {
        return this.pageDesign.pageStyles.mobileImageAlign;
      } else {
        return this.pageDesign.pageStyles.imageAlign;
      }
    },
    showLeftImage() {
      return (
        (this.pageDesign.imageBlock.noResultSrc !== "" &&
          this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" || this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.pageDesign.imageBlock.noResultSrc !== "" &&
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getSelectedOptions",
      "getPreviewSettings",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getEnvConst",
      "getTotalPreviewPages",
      "getSliderValues",
      "getkozimaIntervalValue"
    ]),
    getEnv() {
      // ...mapGetters(["getEnvConst"]),
      // getEnvConst
      return this.getEnvConst;
    },
    isKozimaGame(){
     return this.getEnv?.KOZIMA_GAME?JSON.parse(this.getEnv.KOZIMA_GAME)[0].quizId==this.getPreviewSettings.quizId:false
    },
    allowedAuthUser() {
      return this.getEnv?.VUE_APP_ALLOWED_AUTH_USER_ID ?? null;
    },
    baldanClientId() {
      return this.getEnv?.VUE_APP_ITALIAN_QUIZID ?? null;
    },

    chemicalClientQuizId() {
      return this.getEnv?.VUE_APP_CHEMICAL_CLIENT_QUIZID ?? null;
    },

    AutoGrowResultPageClients() {
      return (
        this.getPreviewSettings.quizId == this.chemicalClientQuizId ||
        this.getEnv?.VUE_APP_AUTOGROWRESULTPAGE_QUIZID.includes(
          this.getPreviewSettings.quizId
        )
      );
    },

    isBearWithBenefitsClient() {
      return this.getPreviewSettings.quizId
        ? process.env.VUE_APP_BEARWBENEFITS_QUIZID.includes(
            this.getPreviewSettings.quizId
          )
        : false;
    },
    getLeadObjfromVuex() {
      return this.getSavedLeadID;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (this.CurrentPageType == "noResultPage") {
        if (
          this.isNoResultSrcPresent &&
          this.pageDesign.imageBlock.noResultSrc !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.pageDesign.imageBlock.src !== "") return true;
        return false;
      }
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no" ? true : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      if (this.CurrentPageType == "resultPage") return "col-12";
      if (
        this.CurrentPageType == "noResultPage" &&
        this.pageDesign.imageBlock.noResultSrc == ""
      )
        return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6 ";
    },
    CurrencySign() {
      return this.getPreviewSettings.currencySign.sign;
    },
    isHidePrice() {
      return this.getPreviewSettings.hidePrice;
    },
    getProductArrayIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "product"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },

    getProductBlock() {
      return this.pageDesign.blocksArray[this.getProductArrayIndex];
    },

    couponCompIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "coupon"
      );
      return index;
    },

    showCouponComp() {
      if (this.couponCompIndex <= -1) return false;

      const { couponAppliedWhen } =
        this.pageDesign.blocksArray[this.couponCompIndex];
      const leadData = this.getSavedLeadID;

      const isValid = couponAppliedWhen.every((lead) =>
        Object.keys(leadData).some(
          (key) => lead.value === key && leadData[key] !== ""
        )
      );

      return isValid;
    },

    currencyPos() {
      const index = this.getProductArrayIndex;
      if (index) {
        if (this.pageDesign.blocksArray[index].style.Currencyposition) {
          return this.pageDesign.blocksArray[index].style.Currencyposition;
        }
        return "left";
      }
      return null;
    },
    currencySpace() {
      if (this.CurrencySign == "RON") return true;
      return false;
    },
  },
  destroyed() {
    this.stopAudio()
  },
  mounted() {    
    if(this.isKozimaGame&&this.getkozimaIntervalValue)
  {
    this.setupAudio();
    clearInterval(this.getkozimaIntervalValue)
  }
    //dynamic iframe height adjust for Rare Cannaboid Client
    if (this.AutoGrowResultPageClients) {
      window.parent.postMessage({ page: "ResultPage" }, "*");
    }
    this.pageDesign = this.getResultPageValues();
    this.UpdateDynamicText();
    let vm = this;
    window.addEventListener("message", (event) => {
      let responseData = event.data;
      if (vm.apiToken == responseData.quizKey) {
        if (typeof vm.currentAddToCartProductIndex == "number") {
          vm.products[vm.currentAddToCartProductIndex].loading = false;
        }
        vm.currentAddToCartProductIndex = null;
        if (responseData.json.status) {
          vm.$toasted.show(responseData.json.description, {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 5000,
          });

          return true;
        } else {
          if (vm.checkSpecialClient() == "CracotteClient") {
            vm.$toasted.show("Produit ajouté au panier", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 5000,
            });
          } else {
            vm.$toasted.show("Product Added to Cart!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 5000,
            });
          }
        }
      }
    });
  },
  async created() {
    if (this.$route.query.result_key) {
      this.products = this.resultPreviewProducts;
      // if(this.$route.query.email){
      //   this.getUpdateCollectionData();
      // }
    } else {
      if (!this.isElthClientCollectionUpdate) {
        this.submitAnswer();
        this.getAppInstalledByQuiz("judgeme");
      } else {
        const QuizID = this.getPreviewSettings.quizId;
        if (this.allowedAuthUser.includes(QuizID.toString()))
          this.isProductTableUser = true;
        // if (this.isElthClientQuizContinue) {
        //   await this.submitAnswer();
        //   this.getUpdateCollectionData();
        // } else {
        //   this.getUpdateCollectionData();
        // }

        // Directly show Update Section Elth/Baldan
        this.products = this.sampleElthProduct;
      }
    }
  },
  watch: {},
};
</script>
<style scoped>

.detailOnlyDescriptionContainer >>> p {
  margin-bottom:8px;
}
.images-size label {
  color: #b3afb6;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 5px !important;
  padding-right: 2rem;
}
.custom-select-aanswer.result-select {
  border: none !important;
  width: 100%;
  padding-left: 0px;
}
.result-select {
  padding-left: 35px;
  background: #f4f3f5;
  height: inherit;
  border: none !important;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}
.question-type-select {
  background-color: transparent;
  border: none;
  padding: 4px 39px !important;
  height: 36px;
  width: 100%;
}
.sidebar {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.big-div {
  padding: 0px;
}

/* Carousel CSS  */
.ProductCarousel >>> .VueCarousel-pagination {
  height: 40px !important;
  visibility: hidden !important;
}
.ProductCarousel >>> .VueCarousel-navigation {
  position: absolute;
  top: 95%;
  left: 50%;
}

/* Judge Me Css */
.Review >>> .jdgm-prev-badge {
  display: block !important;
}

.Review >>> .jdgm-star {
  display: inline !important;
  text-decoration: none !important;
  padding: 0 4px 0 0 !important;
  margin: 0 !important;
  font-weight: bold;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
}
.Review >>> .jdgm-preview-badge .jdgm-star.jdgm-star {
  color: #eab304;
}

.Review >>> .jdgm-star.jdgm--on:before {
  font-family: FontAwesome;
  content: "\f005";
  color: #eab304;
}
.Review >>> .jdgm-star.jdgm--half:before {
  font-family: FontAwesome;
  content: "\f123";
  color: #eab304;
}
.Review >>> .jdgm-star.jdgm--off:before {
  font-family: FontAwesome;
  content: "\f006";
  color: #eab304;
}

.tooltip-zIndex {
  position: relative;
  z-index: 11111;
}
</style>
